import React from 'react';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';
import MainMenuBtn from '../menu-settings/main-menu-btn';
import SideBar from '../side-bar/side-bar';

const WelcomeProvider = () => {

    return (
        <div className="provider-info">
            <div className="divider grid-flex">
                <SideBar />
                <div id="page" className="site">
                    <div className="main-content">
                        <header className='site-header'>
                            <div className="flex-between">
                                <h1 className="main-title">
                                    {getTranslatedText('welcomeProvider', store)}
                                </h1>
                                <div className="side-functionality">
                                    <div className="side-button grid-flex">
                                        <MainMenuBtn />
                                    </div>
                                </div>
                            </div>
                        </header>
                        <main className="spacing">
                            <h2 className="main-title">
                                {getTranslatedText('welcomeProviderText', store)}
                            </h2>

                        </main>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default WelcomeProvider;
