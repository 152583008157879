import React from 'react';

const DefaultFilter = ({ filter, providerFilters, setProviderFilters }) => {

    const addFilter = (item) => {
        item.selected = !item.selected;
        if (item.selected) {
            setProviderFilters({
                ...providerFilters,
                filters: providerFilters.filters.length > 0 ? providerFilters.filters.concat(item) : [item],

            });
        } else {
            setProviderFilters({
                ...providerFilters,
                filters: providerFilters.filters.length > 0 ? providerFilters.filters.filter(item => item.id !== filter.id) : [],

            });
        }

    };

    return (
        <div className={"filter-import-container "}>
            <div
                onClick={() => addFilter(filter)}
                className={`filter-import  ${
                    filter.selected ? "active" : ""
                }`.trim()}
            >
                {filter.name}
            </div>
        </div>
    );

};
export default DefaultFilter;
