import Config from "../Config";

const initialState = {
  type: null,
  loading: false,
  dinein: {},
  category: null,
  isAdmin: false,
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
      case Config.REDUCER_TYPES.SET_LOADING:
          return {
              ...state,
              ...{ type: action.type, loading: action.payload },
          };
      case Config.REDUCER_TYPES.SET_DINEIN:
          return {
              ...state,
              ...{ type: action.type, dinein: action.payload },
          };
      case Config.REDUCER_TYPES.SET_CATEGORY:
          return {
              ...state,
              ...{ type: action.type, category: action.payload },
          };
      case Config.REDUCER_TYPES.SET_IS_ADMIN:
          return {
              ...state,
              ...{ type: action.type, isAdmin: action.payload },
          };
      default:
          return state;
  }
};

export default Reducer;
