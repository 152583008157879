import { Link } from "react-router-dom";
import "./language-badges.css";

/**
 * @param {{content:string, isSupported?:boolean, link?:string}} props
 */
const LanguageBadge = (props) => {
    const { content, isSupported, link } = props;
    const className = `lang-badge lang-badge--${
        isSupported ? "supported" : "unsupported"
    }`;

    return link ? (
        <Link to={link} className={className}>
            {content}
        </Link>
    ) : (
        <span className={className}>{content}</span>
    );
};

/**
 * @param {{items:{content:string, isSupported?:boolean, link?:string}[]}} props
 */
export const LanguageBadges = (props) => {
    const { items } = props;

    return items && items.length ? (
        <div className="lang-badge__list">
            {items.map(({ content, link, isSupported }) => (
                <LanguageBadge
                    key={content}
                    content={content}
                    isSupported={isSupported}
                    link={link}
                />
            ))}
        </div>
    ) : null;
};
