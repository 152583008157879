import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import FilterModel from '../../data/filter-model';
import ProviderModel from '../../data/provider-model';
import MainMenuBtn from '../menu-settings/main-menu-btn';
import SideBar from '../side-bar/side-bar';
import DefaultFilter from './default-filter';
import { useDispatch, useSelector } from 'react-redux';
import HCNLoader from '../../helpers/hcn-loader';
import { toast } from 'react-toastify';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';
import { selectProviderId } from '../../redux/provider/selectors';

const ImportDefaultFilters = (props) => {
    const providerId = useSelector(selectProviderId);
    const history = useHistory();
    const [filters, setFilters] = useState([]);
    const [providerFilters, setProviderFilters] = useState({
        providerId,
        filters: [],
    });
    const [existingFilters, setExistingFilters] = useState([]);
    const [currentFilters, setCurrentFilters] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        HCNLoader.show(dispatch);
        FilterModel.remote_getAll()
            .then((filters) => {
                setFilters(
                    filters.map((filter) => {
                        return {
                            ...filter,
                            selected: false,
                        };
                    })
                );
                HCNLoader.hide(dispatch);
            })
            .catch((err) => {
                toast(err.message);
                HCNLoader.hide(dispatch);
            });
        providerId &&
            FilterModel.remote_getAllById(providerId)
                .then((existingFs) => {
                    setExistingFilters(existingFs);
                })
                .catch((err) => {
                    toast(err.message);
                });
    }, [providerId, dispatch]);

    useEffect(() => {
        filters &&
            existingFilters &&
            setCurrentFilters(
                filters.filter(
                    (m) =>
                        !existingFilters.find(
                            (x) => x.name === m.name && x.title === m.title
                        )
                )
            );
    }, [filters, existingFilters]);

    const selectAll = () => {
        setCurrentFilters(
            currentFilters.map((item) => ({ ...item, selected: true }))
        );
        setFilters(filters.map((item) => ({ ...item, selected: true })));
        setProviderFilters({
            ...providerFilters,
            filters: filters.concat(currentFilters.filter((x) => x.selected)),
        });
    };

    const removeAll = () => {
        setCurrentFilters(
            currentFilters.map((item) => ({ ...item, selected: false }))
        );
        setFilters(filters.map((item) => ({ ...item, selected: false })));
        setProviderFilters({
            ...providerFilters,
            filters: existingFilters,
        });
    };

    const createProviderFilters = () => {
        const payload = {
            providerId: providerFilters.providerId,
            filters: providerFilters.filters.map((item) => ({
                acronym: item.acronym,
                name: item.name,
            })),
        };

        HCNLoader.show(dispatch);
        ProviderModel.createProviderFilters(payload)
            .then(() => {
                history.push("/filters");
            })
            .catch((err) => {
                toast(err.message);
            })
            .finally(() => {
                HCNLoader.hide(dispatch);
            });
    };
    const onCancel = () => {
        history.push("/filters");
    };

    return (
        <div className="provider-info">
            <div className="divider grid-flex">
                <SideBar />
                <div id="page" className="site">
                    <div className="main-content">
                        <header className="site-header">
                            <div className="flex-between">
                                <h1 className="main-title">
                                    {getTranslatedText(
                                        "selectProviderFilters",
                                        store
                                    )}
                                </h1>
                                <div className="side-functionality">
                                    <div className="side-button grid-flex">
                                        <button
                                            onClick={onCancel}
                                            className="btn"
                                        >
                                            {getTranslatedText("cancel", store)}
                                        </button>
                                        <button
                                            onClick={createProviderFilters}
                                            className="btn default-btn"
                                        >
                                            {getTranslatedText("import", store)}
                                        </button>
                                        <MainMenuBtn />
                                    </div>
                                </div>
                            </div>
                        </header>
                        <main>
                            <div className="select-buttons">
                                <button
                                    onClick={selectAll}
                                    type="button"
                                    className="select-all"
                                >
                                    {getTranslatedText("selectAll", store)}
                                </button>
                                <button
                                    onClick={removeAll}
                                    type="button"
                                    className="clear-all"
                                >
                                    {getTranslatedText("clearAll", store)}
                                </button>
                            </div>
                            <section className="container">
                                <div className="filter-wrapper import-filter-wrapper grid-flex-wrap">
                                    {currentFilters &&
                                        currentFilters.map((filter, index) => (
                                            <DefaultFilter
                                                providerFilters={
                                                    providerFilters
                                                }
                                                setProviderFilters={
                                                    setProviderFilters
                                                }
                                                filter={filter}
                                                key={index}
                                            />
                                        ))}
                                </div>
                            </section>
                        </main>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImportDefaultFilters;
