import React from 'react';
import { useEffect, useState } from 'react';
import { Link, generatePath } from 'react-router-dom';
import FilterModel from '../../data/filter-model';
import ProviderModel from '../../data/provider-model';
import InfoModal from '../info-modal';
import { useDispatch, useSelector } from 'react-redux';
import HCNLoader from '../../helpers/hcn-loader';
import { toast } from 'react-toastify';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';
import { LanguageBadges } from '../translations/language-badges';
import Config from '../../Config';
import { selectProviderId, selectProviderLocales } from '../../redux/provider/selectors';

const Filter = ({ filter, filters, setFilters }) => {
    const providerId = useSelector(selectProviderId);
    const langs = useSelector(selectProviderLocales);

    const [show, setShow] = useState(false);
    const [infoMessage, setInfoMessage] = useState({
        message: '',
        confirmation: false,
    });
    const dispatch = useDispatch();

    const hideModal = () => {
        setShow(false);
    };

    const deleteFilter = () => {
        setShow(true);
        setInfoMessage({
            ...infoMessage,
            message: getTranslatedText('deleteFilterWarning', store),
        });
    };

    const generateLink = (langCode = Config.DEFAULT_LANG_CODE) => {
        const path = generatePath("/add-filter/:filterId?/:langCode?", {
            filterId: filter.id,
            langCode,
        });

        return path;
    };

    useEffect(() => {
        const deleteProviderFilter = () => {
            HCNLoader.show(dispatch);
            ProviderModel.deleteProviderFilterById(filter.id)
                .then(() => {
                    setFilters(filters.filter((item) => item.id !== filter.id));
                    HCNLoader.hide(dispatch);
                })
                .catch((err) => {
                    toast(err.message);
                    HCNLoader.hide(dispatch);
                });
        };

        const deleteFilterInHCN = () => {
            HCNLoader.show(dispatch);
            FilterModel.deleteFilterById(filter.id)
                .then(() => {
                    setFilters(filters.filter((item) => item.id !== filter.id));
                    HCNLoader.hide(dispatch);
                })
                .catch((err) => {
                    toast(err.message);
                    HCNLoader.hide(dispatch);
                });
        };

        if (infoMessage.confirmation) {
            providerId ? deleteProviderFilter() : deleteFilterInHCN();
        }

        return () => {
            setInfoMessage({});
        };
    }, [
        providerId,
        infoMessage.confirmation,
        dispatch,
        filter.id,
        filters,
        setFilters,
    ]);

    return (
        <div className="row flex-between add-row-action">
            <InfoModal
                setInfoMessage={setInfoMessage}
                infoMessage={infoMessage}
                hideModal={hideModal}
                show={show}
            />
            <div style={{ width: "18rem", maxWidth: "33%" }}>
                <Link to={generateLink()}>
                    <h2>{filter.name}</h2>
                </Link>
            </div>
            {providerId ? (
                <LanguageBadges
                    items={langs.map((lang) => ({
                        content: lang.code,
                        isSupported: true,
                        link: generateLink(lang.code),
                    }))}
                />
            ) : null}
            <div className="flex-start">
                <Link to={{
                    pathname: '/add-filter/',
                    state: {
                        filterName: filter.name,
                        filterAcronym: filter.acronym
                    }
                }}>
                    <button className="copy">
                        <img src="../img/svg/duplicate.svg" alt="double item" />
                    </button>
                </Link>

                <button onClick={deleteFilter} className="remove">
                    <img src="../img/svg/trash.svg" alt="double item" />
                </button>
            </div>
        </div>
    );
};

export default Filter;
