import React from 'react';
import { Link } from 'react-router-dom';
import { getTranslatedText } from '../../../helpers/checkTranslations';
import { store } from '../../../redux/store';
import { default as plus } from '../plus-sign.svg';

const Items = () => {
    return (
        <div className="sidebar-component">
            <div className="flex-between">
                <Link to="/rootCategory-items">
                    <p data-component="provider">{getTranslatedText('items', store)}</p>
                </Link>
                <Link to={`/add-rootCategory-item`}>
                    <button className="btn add-btn">
                        <span>
                            <img src={plus} alt="add" />
                        </span>
                    </button>
                </Link>
            </div>
        </div>
    );
};
export default Items;
