import React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ModifierModel from '../../data/modifier-model';
import MainMenuBtn from '../menu-settings/main-menu-btn';
import SideBar from '../side-bar/side-bar';
import DefaultModifier from './default-modifier';
import { useDispatch, useSelector } from 'react-redux';
import HCNLoader from '../../helpers/hcn-loader';
import { toast } from 'react-toastify';
import { store } from '../../redux/store';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { selectProviderId } from '../../redux/provider/selectors';
import { useStateSafe } from '../../hooks';


const ImportDefaultModifier = props => {
    const history = useHistory();
    const dispatch = useDispatch();

    const providerId = useSelector(selectProviderId);

    const [modifiers, setModifiers] = useStateSafe([]);
    const [providerModifiers, setProviderModifiers] = useStateSafe({
        providerId,
        modifiers: [],
    });
    const [existingModifiers, setExistingModifiers] = useStateSafe([]);

    const selectAll = () => {
        console.log(1);
        setModifiers(modifiers.filter(m => !existingModifiers.find(x => x.name === m.name && x.title === m.title)).map(item => {
            return { ...item, selected: true };
        }));
        setProviderModifiers(
            {
                ...providerModifiers,
                modifiers: modifiers,
            },
        );
    };

    const removeAll = () => {
        console.log(2);
        setModifiers(
            modifiers.filter(m => !existingModifiers.find(x => x.name === m.name && x.title === m.title)).map(item => {
                return { ...item, selected: false };
            }));
        setProviderModifiers({
            providerId,
            modifiers: [],
        });
    };

    const createProviderModifiers = () => {
        let rsMdfs = {
            ...providerModifiers,
            modifiers: providerModifiers.modifiers.map(modif => {
                return {
                    ...modif,
                    import: true,
                };
            }),

        };

        HCNLoader.show(dispatch);
        ModifierModel
            .createProviderModifiers(rsMdfs)
            .then(() => {
                HCNLoader.hide(dispatch);
                history.push('/modifiers');
            })
            .catch(err => {
                toast(err.message);
                HCNLoader.hide(dispatch);
            });
    };

    const onCancel = () => {
        history.push('/modifiers');
    };

    useEffect(() => {
        const fetchModifiers = () => {
            HCNLoader.show(dispatch);
            ModifierModel.remote_getAll()
                .then((modifier) => {
                    let lts = modifier.map((modifier) => {
                        return {
                            ...modifier,
                            rawItems:
                                modifier.rawItems &&
                                modifier.rawItems.map((item) => {
                                    return {
                                        ...item,
                                        filtersOut:
                                            item.filtersOut &&
                                            item.filtersOut.map((it) => it.id),
                                    };
                                }),
                            selected: false,
                        };
                    });
                    setModifiers(lts);
                    HCNLoader.hide(dispatch);
                })
                .catch((err) => {
                    toast(err.message);
                    HCNLoader.hide(dispatch);
                });

            providerId &&
                ModifierModel.getProviderModifiersById(providerId)
                    .then((existingMods) => {
                        setExistingModifiers(existingMods);
                    })
                    .catch((err) => {
                        toast(err.message);
                    });
        };

        fetchModifiers();
    }, [dispatch, providerId, setExistingModifiers, setModifiers]);

    return (
        <div className="provider-info">
            <div className="divider grid-flex">
                <SideBar />
                <div id="page" className="site">
                    <div className="main-content">
                        <header className='site-header'>
                            <div className="flex-between">
                                <h1 className="main-title">
                                    {getTranslatedText('selectProviderModifier', store)}
                                </h1>
                                <div className="side-functionality">
                                    <div className="side-button grid-flex">
                                        <button onClick={onCancel} className="btn">{getTranslatedText('cancel', store)}</button>
                                        <button onClick={createProviderModifiers} className="btn default-btn">{getTranslatedText('save', store)}</button>
                                        <MainMenuBtn />
                                    </div>
                                </div>
                            </div>
                        </header>
                        <main>
                            <div className="select-buttons">
                                <button onClick={selectAll} type="button" className="select-all">{getTranslatedText('selectAll', store)}</button>
                                <button onClick={removeAll} type="button" className="clear-all">{getTranslatedText('clearAll', store)}</button>
                            </div>
                            <section className="container">
                                <div className="filter-wrapper import-filter-wrapper grid-flex-wrap">
                                    {modifiers && modifiers.filter(m => !existingModifiers.find(x => x.name === m.name && x.title === m.title)).map((modifier, index) => {
                                        return <DefaultModifier key={index} providerModifiers={providerModifiers}
                                            setProviderModifiers={setProviderModifiers}
                                            existingModifiers={existingModifiers}
                                            modifier={modifier} />;
                                    })}
                                </div>
                            </section>
                        </main>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImportDefaultModifier;
