import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FilterModel from '../../data/filter-model';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';
import MainMenuBtn from '../menu-settings/main-menu-btn';
import SideBar from '../side-bar/side-bar';
import Filter from './filter';
import { useDispatch, useSelector } from 'react-redux';
import { selectProviderId } from '../../redux/provider/selectors';
import HCNLoader from '../../helpers/hcn-loader';
import { useStateSafe } from '../../hooks';

const Filters = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const providerId = useSelector(selectProviderId);

    const [filters, setFilters] = useStateSafe([]);

    useEffect(() => {
        HCNLoader.show(dispatch);
        // Things in here are very wrong
        providerId
            ? FilterModel.remote_getAllById(providerId)
                  .then((filters) => {
                      if (filters.length === 0) {
                          history.push("/import-default-filter");
                      }
                      setFilters(filters);
                  })
                  .finally(() => {
                      HCNLoader.hide(dispatch);
                  })
            : FilterModel.remote_getAll()
                  .then((filters) => {
                      if (filters.length === 0) {
                          history.push("/add-filter");
                      }
                      setFilters(filters);
                  })
                  .finally(() => {
                      HCNLoader.hide(dispatch);
                  });

        return () => {
            HCNLoader.hide(dispatch);
            setFilters([]);
        };
    }, [history, providerId, setFilters, dispatch]);
    const addFilter = () => {
        history.push('/add-filter');
    };

    return (
        <div className="divider grid-flex">
            <SideBar />
            <div id="page" className="site">
                <div className="main-content">
                    <header className='site-header'>
                        <div className="flex-between">
                            <h1 className="main-title">
                                {getTranslatedText('filters', store)}
                            </h1>
                            <div className="side-functionality">
                                <div className="side-button grid-flex">
                                    {providerId ?
                                        <>
                                            <button
                                                onClick={() => {
                                                    history.push('/import-default-filter')
                                                }}
                                                className="btn default-btn"
                                            >
                                                {getTranslatedText('importFilter', store)}
                                            </button>
                                            <button onClick={addFilter} className="btn default-btn">
                                                {getTranslatedText('addFilter', store)}
                                            </button>
                                        </>
                                        : <button onClick={addFilter} className="btn default-btn">
                                            {getTranslatedText('addFilter', store)}
                                        </button>}
                                    <MainMenuBtn />
                                </div>
                            </div>

                        </div>
                    </header>
                    <main>
                        {filters.length > 0 ? filters.map((filter, index) =>
                            <Filter filters={filters} setFilters={setFilters} key={index} filter={filter} />
                        ) : null}
                    </main>
                </div>
            </div>
        </div>
    );
};
export default Filters;
