import { useMemo } from "react";
import { TabItem, TabPanel, Tabs } from "../ui/tabs";
import Config from "../../Config";

/**
 * @param {{ langs: {code: string, name: string}[], selectedCode?: string, handleTabChange: (newCode: string) => void, children: React.ReactNode}} props 
 */
export const LangTabs = (props) => {
    let { langs, selectedCode, handleTabChange, children } = props;

    if (!selectedCode) selectedCode = Config.DEFAULT_LANG_CODE;

    const hasChildren = !!children;

    const renderTabList = () => {
        const tabList = langs.map((lang) => {
            const tabContent = <span>{lang.name}</span>;
            const tabKey = `tab-${lang.code}`;
            return (
                <TabItem key={tabKey} type="button">
                    {tabContent}
                </TabItem>
            );
        });

        return tabList;
    };

    const activeIndex = useMemo(() => {
        const index = langs.findIndex((lang) => lang.code === selectedCode);
        return index >= 0 ? index : 0;
    }, [langs, selectedCode]);

    const onTabClick = (_event, index) => {
        handleTabChange(langs[index].code);
    };

    return langs.length ? (
        <>
            <Tabs activeIndex={activeIndex} onTabClick={onTabClick}>
                {renderTabList()}
            </Tabs>
            {hasChildren ? (
                <TabPanel
                    key={`panel-${selectedCode}`}
                    index={activeIndex}
                    activeIndex={activeIndex}
                >
                    {children}
                </TabPanel>
            ) : null}
        </>
    ) : (
        <>{children}</>
    );
};