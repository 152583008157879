import { toast } from "react-toastify";
import Config from "../../Config";
import LocalData from "../../helpers/local-data";
import { FETCH_PROVIDER_DATA_ERROR, FETCH_PROVIDER_DATA_REQUEST, FETCH_PROVIDER_DATA_SUCCESS, RESET_PROVIDER, SET_PROVIDER, SET_PROVIDER_ID, SET_PROVIDER_LOCALES } from "./actions";

const providerFromLocalData = () => {
    let value = LocalData.get(Config.LOCAL_DATA_KEYS.PROVIDER) || null;

    try {
        value = JSON.parse(value);
    } catch (e) {
        value = null;
    }
    return value;
};

const initialState = {
    currentProvider: providerFromLocalData(),
    id: LocalData.get(Config.LOCAL_DATA_KEYS.PROVIDER_ID) || null,
    locales: [],
    isError: false,
    error: '',
    isLoading: false
};

export function providerReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PROVIDER:
            if (action.payload) {
                LocalData.set(
                    Config.LOCAL_DATA_KEYS.PROVIDER,
                    JSON.stringify(action.payload)
                );
            } else {
                LocalData.remove(Config.LOCAL_DATA_KEYS.PROVIDER);
            }

            return { ...state, currentProvider: action.payload };
        case SET_PROVIDER_ID:
            if (action.payload) {
                LocalData.set(
                    Config.LOCAL_DATA_KEYS.PROVIDER_ID,
                    action.payload
                );
            } else {
                LocalData.remove(Config.LOCAL_DATA_KEYS.PROVIDER_ID);
            }

            return { ...state, id: action.payload };
        case SET_PROVIDER_LOCALES:
            return { ...state, locales: action.payload };
        case RESET_PROVIDER:
            LocalData.remove(Config.LOCAL_DATA_KEYS.PROVIDER);
            LocalData.remove(Config.LOCAL_DATA_KEYS.PROVIDER_ID);
            return {
                ...state,
                currentProvider: null,
                id: null,
                locales: [],
            };
        case FETCH_PROVIDER_DATA_REQUEST:
            return {
                ...state,
                isLoading: true,
                isError: false,
                error: "",
            };
        case FETCH_PROVIDER_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                error: "",
            };
        case FETCH_PROVIDER_DATA_ERROR:
            toast.error(action.payload, { autoClose: 3000 });
            return {
                ...state,
                isLoading: false,
                isError: true,
                error: action.payload,
            }
        default:
            return state;
    }
}