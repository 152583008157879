import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Context } from '../../../App';
import RootCategoryModel from '../../../data/rootCategory-model';
import { getTranslatedText } from '../../../helpers/checkTranslations';
import HCNLoader from '../../../helpers/hcn-loader';
import { store } from '../../../redux/store';
import InfoModal from '../../info-modal';
import './rootCategory.css';
import Config from '../../../Config';

const RootCategoryItem = ({ rootCategory }) => {
    const context = useContext(Context);

    const [showIM, setShowIM] = useState(false);
    const [infoMessage, setInfoMessage] = useState({
        message: '',
        confirmation: false,
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if (infoMessage.confirmation) {
            HCNLoader.show(dispatch);
            RootCategoryModel
                .deleteRootCategory(rootCategory.id)
                .then(() => {
                    context.setRootCategories(context.rootCategories.filter(item => item.id !== rootCategory.id))
                    HCNLoader.hide(dispatch);
                })
                .catch(err => {
                    toast(err.message);
                    HCNLoader.hide(dispatch);
                });
        }
        return () => {
            setInfoMessage({});
        };
    }, [infoMessage.confirmation]);

    const setActiveRootCategory = () => {

        context.setRootCategories(context.rootCategories.map(item => {
            if (item.id === rootCategory.id) {
                return { ...item, selected: true };
            }
            return { ...item, selected: false };
        }));
    };

    const hideIMModal = () => {
        setShowIM(false);
    };

    const deleteRootCategory = id => {
        setShowIM(true);
        setInfoMessage({
            ...infoMessage,
            message: getTranslatedText('deleteRootCategoryWarning', store),
            itemId: id,
        });
    };

    return (
        <div className='flex-start-center '>
            <InfoModal
                setInfoMessage={setInfoMessage}
                infoMessage={infoMessage}
                hideModal={hideIMModal}
                show={showIM}
            />
            <span style={{ marginRight: 8 }}>=</span>
            <Link to={`/add-rootCategory/${rootCategory.id}/${Config.DEFAULT_LANG_CODE}`}>
                <li onClick={setActiveRootCategory} className={`rootCategory-category ${rootCategory.selected ? 'active' : ''}`}>
                    {rootCategory.name}
                </li>
            </Link>
            <button className="remove margin-left-auto padding-20" onClick={deleteRootCategory}>
                x
            </button>
        </div>
    );
};
export default RootCategoryItem;
