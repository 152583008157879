import { useCallback, useState } from "react";
import { useIsMounted } from "./useIsMounted";

/**
 * @template T
 * @param {T} initialState 
 * @returns {[T, (prevState: T | ((prevState: T) => T)) => void]}
 * @description usefull for setting state inside useEffect after reqeusts. setter function is wrapped in useCallback so no worries
 */
export const useStateSafe = (initialState) => {
    const [state, setState] = useState(initialState);
    const isMounted = useIsMounted();

    const setSafeState = useCallback(
        (state) => {
            if (isMounted()) setState(state);
        },
        [isMounted]
    );

    return [state, setSafeState];
};