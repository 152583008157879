import React from "react";
import FilterModel from "../../data/filter-model";
import Filters from "./filters/filters";
import HCNLogo from "./hcn-logo";
import Items from "./items/items";
import RootCategory from "./rootCategory/rootCategory";
import Modifiers from "./modifiers/modifiers";
import SidebarProvider from "./provider/side-bar-provider";
import SidebarProviderGroups from "./provider-groups/provider-groups";
import SidebarProviders from "./provider/side-bar-providers";
import TimeSettings from "./time-settings/time-settings";
import Coupons from "./coupons/Coupons";
import {useDispatch, useSelector} from "react-redux";
import { selectProvider, selectProviderId } from "../../redux/provider/selectors";
import { resetProvider } from "../../redux/provider/actions";

const SideBar = (props) => {
  const dispatch = useDispatch();
  const providerId = useSelector(selectProviderId);
  const isAdmin = useSelector((state) => state.Reducer.isAdmin);
  const provider = useSelector(selectProvider);

  const setDefaultId = () => {
    dispatch(resetProvider(null));
  }

  return (
    <div className="sidebar">
      <div className="sidebar-header flex-center">
        {providerId && provider?.name ? (
          <h1>{provider.name}</h1>
        ) : (
          <HCNLogo shouldRedirectToPortal={true}/>
        )}
      </div>
      <div className="sidebar-main">
        <div className="sidebar-component">
          <div className="flex-between">
            {providerId ? (
              <SidebarProvider />
            ) : (
              <SidebarProviders />
            )}
          </div>
        </div>

        {!providerId && isAdmin && (
          <div className="sidebar-component">
            <div className="flex-between">
              <SidebarProviderGroups providerId={providerId} />
            </div>
          </div>
        )}

        {(providerId || isAdmin) && (
          <div className="sidebar-component">
            <div className="flex-between">
              <Filters providerId={providerId} />
            </div>
          </div>
        )}

        {(providerId || isAdmin) && (
          <div className="sidebar-component">
            <div className="flex-between">
              <Modifiers providerId={providerId} />
            </div>
          </div>
        )}

        {!providerId && isAdmin && (
          <div className="sidebar-component">
            <div className="flex-between">
              <Coupons />
            </div>
          </div>
        )}

        {providerId ? <Items /> : null}
        {providerId ? <RootCategory /> : null}
        {providerId ? (
          <TimeSettings addClicked={props.timeSettingsAddClicked} />
        ) : null}
      </div>
      <div
        onClick={setDefaultId}
        className="flex-center sidebar-site-logo margin-top-double">
        {providerId ? <HCNLogo shouldRedirectToPortal={false} /> : null}
      </div>
    </div>
    // <div className="sidebar">
    //   <div className="sidebar-header flex-center">
    //     {FilterModel.providerId ? (
    //       <h1>{FilterModel.provider.name}</h1>
    //     ) : (
    //       <HCNLogo shouldRedirectToPortal={true}/>
    //     )}
    //   </div>
    //   <div className="sidebar-main">
    //     <div className="sidebar-component">
    //       <div className="flex-between">
    //         {FilterModel.providerId ? (
    //           <SidebarProvider />
    //         ) : (
    //           <SidebarProviders />
    //         )}
    //       </div>
    //     </div>

    //     {!FilterModel.providerId && isAdmin && (
    //       <div className="sidebar-component">
    //         <div className="flex-between">
    //           <SidebarProviderGroups providerId={FilterModel.providerId} />
    //         </div>
    //       </div>
    //     )}

    //     {(FilterModel.providerId || isAdmin) && (
    //       <div className="sidebar-component">
    //         <div className="flex-between">
    //           <Filters providerId={FilterModel.providerId} />
    //         </div>
    //       </div>
    //     )}

    //     {(FilterModel.providerId || isAdmin) && (
    //       <div className="sidebar-component">
    //         <div className="flex-between">
    //           <Modifiers providerId={FilterModel.providerId} />
    //         </div>
    //       </div>
    //     )}

    //     {!FilterModel.providerId && isAdmin && (
    //       <div className="sidebar-component">
    //         <div className="flex-between">
    //           <Coupons />
    //         </div>
    //       </div>
    //     )}

    //     {FilterModel.providerId ? <Items /> : null}
    //     {FilterModel.providerId ? <RootCategory /> : null}
    //     {FilterModel.providerId ? (
    //       <TimeSettings addClicked={props.timeSettingsAddClicked} />
    //     ) : null}
    //   </div>
    //   <div
    //     onClick={setDefaultId}
    //     className="flex-center sidebar-site-logo margin-top-double">
    //     {FilterModel.providerId ? <HCNLogo shouldRedirectToPortal={false} /> : null}
    //   </div>
    // </div>
  );
};

export default SideBar;
