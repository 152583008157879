import React, { useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ItemModel from '../../data/item-model';
import MainMenuBtn from '../menu-settings/main-menu-btn';
import SideBar from '../side-bar/side-bar';
import './rootCategory-items.css';
import ProviderModifiersFilters from './provider-modifiers-filters';
import HCNImages from '../../helpers/hcn-images';
import DropZone from '../drop-zone/drop-zone';
import { v4 as uuidv4 } from 'uuid';
import HCNLoader from '../../helpers/hcn-loader';
import UploadModel from '../../data/upload-model';
import { useDispatch, useSelector } from 'react-redux';
import Config from '../../Config';
import { toast } from 'react-toastify';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';
import Multiselect from "multiselect-react-dropdown";
import HotelModel from "../../data/hotel-model";
import HCNString from '../../helpers/hcn-string';

import { LangTabs } from '../translations/language-tabs';
import { selectProvider, selectProviderId, selectProviderLocales } from '../../redux/provider/selectors';
import { useStateSafe } from '../../hooks';

const ACCEPTED_MIME_TYPES = "image/gif,image/jpeg,image/png,image/jpg";

// const generateId = () => {
//     const d =
//         typeof performance === "undefined"
//             ? Date.now()
//             : performance.now() * 1000;

//     return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
//         const r = (Math.random() * 16 + d) % 16 | 0;

//         return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
//     });
// };

const initialRootItemState = {
    name: '',
    price: 0,
    description: '',
    images: [],
    filters: [],
    modifiers: [],
    taxes: [],
    isAlcohol: false,
    meta: {},
    placeSettings: true,
};

const ItemForm = ({
    langCode,
    itemId,
    editMode,
    rootCategoryItem,
    setRootCategoryItem,
    providerId,
    isSynergyProvider,
}) => {
    const reactDomHistory = useHistory();
    const dispatch = useDispatch();

    const [uploadedFileName, setUploadedFileName] = useStateSafe({});
    const [synergyTaskCodes, setSynergyTaskCodes] = useStateSafe([]);

    const imageRefs = {
        [Config.IMAGE_SIZES.DEFAULT]: useRef(null),
        [Config.IMAGE_SIZES.MAIN]: useRef(null),
        [Config.IMAGE_SIZES.THUMBNAIL]: useRef(null),
    };

    const imageSizeLabelMap = {
        [Config.IMAGE_SIZES.DEFAULT]: getTranslatedText("defaultImage", store),
        [Config.IMAGE_SIZES.MAIN]: getTranslatedText("mainImage", store),
        [Config.IMAGE_SIZES.THUMBNAIL]: getTranslatedText("Image", store),
    };

    const showUntranslatable = !editMode || langCode === Config.DEFAULT_LANG_CODE;

    const onFilesUpload = (files, size) => {
        if (!files || !files.length) {
            console.log("err");
            return;
        }

        const formData = new FormData();
        const extension = files[0].name.split(".").pop();
        const filename = `${uuidv4()}.${extension}`;
        formData.append("file", files[0], filename);
        formData.append("name", filename);

        HCNLoader.show(dispatch);
        UploadModel.upload(formData)
            .then(() => {
                HCNLoader.hide(dispatch);
                setUploadedFileName((prev) => ({
                    ...prev,
                    [size]: filename,
                }));

                const updatedImages = HCNImages.updateImageObject(
                    rootCategoryItem.images,
                    size,
                    filename
                );

                setRootCategoryItem((prev) => ({
                    ...prev,
                    images: updatedImages,
                }));
            })
            .catch((err) => {
                toast(err.message);
                HCNLoader.hide(dispatch);
            });
    };

    const deleteImage = (size) => {
        const getUpdatedImages = () => {
            if (!Object.values(Config.IMAGE_SIZES).includes(size)) return [];

            const currentImages = rootCategoryItem.images[0] || {};
            const updatedImages = { ...currentImages };

            delete updatedImages[size];

            return [updatedImages];
        };

        setRootCategoryItem((prev) => ({
            ...prev,
            images: getUpdatedImages(),
        }));

        setUploadedFileName({ ...uploadedFileName, [size]: null });
    };

    const getSelectedSynergyTaskCode = () => {
        const task =
            synergyTaskCodes.length !== 0 &&
            synergyTaskCodes.find(
                (task) => task.code === rootCategoryItem.meta?.synergyTaskCode
            );
        return task ? [task] : [];
    };

    useEffect(() => {
        const fetchRootCategoryItems = () => {
            if (!editMode) return;

            HCNLoader.show(dispatch);
            ItemModel.getRootCategoryItems(providerId, langCode)
                .then((res) => {
                    const provider = res.find((item) => item.id === itemId);
                    if (provider) {
                        setRootCategoryItem((prev) => ({
                            ...prev,
                            ...provider,
                        }));
                        setUploadedFileName(
                            ...res.find((item) => item.id === itemId)?.images
                        );
                    } else {
                        setRootCategoryItem(initialRootItemState);
                        setUploadedFileName({});
                    }
                    HCNLoader.hide(dispatch);
                })
                .catch((err) => {
                    toast.error(
                        err && err.message
                            ? err.message
                            : "Something went wrong",
                        {
                            autoclose: 1200,
                        }
                    );
                    HCNLoader.hide(dispatch);
                    reactDomHistory.replace("/add-rootCategory-item");
                });
        };

        if (editMode && providerId) fetchRootCategoryItems();
    }, [
        editMode,
        itemId,
        langCode,
        setRootCategoryItem,
        dispatch,
        reactDomHistory,
        providerId,
        setUploadedFileName,
    ]);

    useEffect(() => {
        if (!isSynergyProvider) return;

        const fetchByProviderId = () => {
            HotelModel.getByProviderId(providerId)
                .then((res) => {
                    return ItemModel.getSynergyTaskCodesBySynergyPID(
                        res.meta?.synergyPID
                    );
                })
                .then((res) => {
                    res &&
                        res.forEach((elem) => {
                            elem.displayValue = `${elem.description} - ${elem.code}`;
                        });
                    setSynergyTaskCodes(res);
                    HCNLoader.hide(dispatch);
                })
                .catch((err) => {
                    toast(err.message);
                    HCNLoader.hide(dispatch);
                });
        };

        fetchByProviderId();
    }, [isSynergyProvider, setSynergyTaskCodes, providerId, dispatch]);

    return (
        <>
            <div
                className="info info-title"
                data-info="item_name"
                style={{ marginTop: 30 }}
            >
                <label htmlFor="item_name">
                    {getTranslatedText("name", store)} <sup>(*)</sup>
                </label>
                <input
                    id="item_name"
                    name="item_name"
                    type="text"
                    value={rootCategoryItem?.name}
                    onChange={(event) =>
                        setRootCategoryItem((prev) => ({
                            ...prev,
                            name: event.target.value,
                        }))
                    }
                />
            </div>
            <div className="info info-title" data-info="item_desc">
                <label htmlFor="item_desc">
                    {getTranslatedText("description", store)}
                </label>
                <input
                    id="item_desc"
                    name="item_desc"
                    type="text"
                    value={rootCategoryItem?.description}
                    onChange={(event) =>
                        setRootCategoryItem((prev) => ({
                            ...prev,
                            description: event.target.value,
                        }))
                    }
                />
            </div>
            {isSynergyProvider && showUntranslatable && (
                <div
                    className="info info-title"
                    data-info="item_desc"
                    style={{ marginTop: "26.5px" }}
                >
                    <label htmlFor="item_synergy_task">
                        {getTranslatedText("synergyTaskCode", store)}{" "}
                        <sup>(*)</sup>
                    </label>
                    <Multiselect
                        id="item_synergy_task"
                        name="item_synergy_task"
                        selectedValues={getSelectedSynergyTaskCode()}
                        style={{
                            multiselectContainer: {
                                width: "400px",
                            },
                            searchBox: {
                                border: "none",
                                borderBottom: "1px solid #1793c2",
                                borderRadius: "0px",
                                width: "400px",
                            },
                        }}
                        options={synergyTaskCodes}
                        avoidHighlightFirstOption={true}
                        placeholder="Search tasks"
                        displayValue="displayValue"
                        selectionLimit={1}
                        onSelect={(_, item) =>
                            setRootCategoryItem({
                                ...rootCategoryItem,
                                meta: Object.assign(rootCategoryItem.meta, {
                                    synergyTaskCode: item.code,
                                }),
                            })
                        }
                        onRemove={() => {
                            const updatedMeta = {
                                ...rootCategoryItem.meta,
                            };
                            delete updatedMeta.synergyTaskCode;
                            setRootCategoryItem({
                                ...rootCategoryItem,
                                meta: updatedMeta,
                            });
                        }}
                    />
                </div>
            )}

            {showUntranslatable ? (
                <div
                    className="info info-title"
                    style={{ width: 150 }}
                    data-info="item_price"
                >
                    <label htmlFor="item_price">
                        {getTranslatedText("price", store)} <sup>(*)</sup>
                    </label>
                    <input
                        id="item_price"
                        name="item_price"
                        type="number"
                        value={
                            !rootCategoryItem?.price
                                ? ""
                                : Math.abs(rootCategoryItem?.price)
                        }
                        min={0}
                        max={1000}
                        onChange={(event) => {
                            event &&
                                setRootCategoryItem({
                                    ...rootCategoryItem,
                                    price: null,
                                });
                            setRootCategoryItem({
                                ...rootCategoryItem,
                                price:
                                    event.target.value > 1000
                                        ? 1000
                                        : event.target.value,
                            });
                        }}
                    />
                </div>
            ) : null}

            {showUntranslatable ? (
                <section className="main-section">
                    {Object.values(Config.IMAGE_SIZES).map((size, index) => (
                        <div className="upload" key={index}>
                            <label>
                                <h3>{imageSizeLabelMap[size]}</h3>
                            </label>
                            {rootCategoryItem &&
                                rootCategoryItem.images &&
                                rootCategoryItem.images.length > 0 &&
                                size in rootCategoryItem.images[0] && (
                                    <div className="uploaded-photo">
                                        <div className="grid-flex">
                                            <img
                                                alt={`${rootCategoryItem.name}`}
                                                src={HCNImages.getFullImageURL(
                                                    rootCategoryItem.images[0],
                                                    size
                                                )}
                                                width="200"
                                            />
                                            <button
                                                className="remove margin-left-auto"
                                                onClick={() =>
                                                    deleteImage(size)
                                                }
                                            >
                                                <img
                                                    src="../img/svg/trash.svg"
                                                    alt="double item"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                )}
                            {!uploadedFileName?.[size] && (
                                <DropZone
                                    accepted_mime_types={ACCEPTED_MIME_TYPES}
                                    text={getTranslatedText(
                                        "ImagdragDrop",
                                        store
                                    )}
                                    multiple={false}
                                    onFilesUpload={(files) => {
                                        onFilesUpload(files, size);
                                    }}
                                    ref={imageRefs[size]}
                                />
                            )}
                        </div>
                    ))}

                    <div style={{ marginTop: 30 }}>
                        <span>{getTranslatedText("isAlcohol", store)}</span>
                        <input
                            type="checkbox"
                            checked={rootCategoryItem.isAlcohol}
                            onChange={(event) => {
                                setRootCategoryItem((prev) => ({
                                    ...prev,
                                    isAlcohol: event.target.checked,
                                }));
                            }}
                        />
                    </div>

                    <ProviderModifiersFilters
                        langCode={langCode}
                        rootCategoryItem={rootCategoryItem}
                        setRootCategoryItem={setRootCategoryItem}
                        itemId={itemId}
                        editMode={editMode}
                    />
                </section>
            ) : null}
        </>
    );
};

const AddRootCategoryItem = () => {
    const { langCode = Config.DEFAULT_LANG_CODE, itemId } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const provider = useSelector(selectProvider);
    const langs = useSelector(selectProviderLocales);
    const providerId = useSelector(selectProviderId);

    const [rootCategoryItem, setRootCategoryItem] = useStateSafe(initialRootItemState);

    const isSynergyProvider = provider?.communicationType === 3;
    const mainRef = useRef();

    const editMode = !!itemId;

    const createRootCategoryItem = () => {
        if (
            !rootCategoryItem.name ||
            !rootCategoryItem.price ||
            !rootCategoryItem.images.length > 0
        ) {
            toast(getTranslatedText("addNamePriceImg", store));
            return;
        }
        // Add validation for Synergy task code
        if (isSynergyProvider && !rootCategoryItem.meta?.synergyTaskCode) {
            toast(getTranslatedText("synergyTaskCodeRequired", store), {
                type: "error",
            });
            return;
        }

        let updatedRootCategoryItem = {
            name: rootCategoryItem.name,
            price: parseFloat(rootCategoryItem.price),
            description: rootCategoryItem.description,
            images: rootCategoryItem.images,
            filters: rootCategoryItem.filters.map((f) =>
                HCNString.isString(f) ? f : f.id
            ),
            modifiers: rootCategoryItem.modifiers.map((m) =>
                HCNString.isString(m) ? m : m.id
            ),
            taxes: rootCategoryItem.taxes.map((t) =>
                HCNString.isString(t) ? t : t.name
            ),
            upsells: rootCategoryItem.upsells
                ? rootCategoryItem.upsells.map((t) => t.id)
                : [],
            isAlcohol: rootCategoryItem.isAlcohol,
            meta: rootCategoryItem.meta,
            placeSettings: rootCategoryItem.placeSettings,
            providerId,
            available: true,
        };

        if (editMode) {
            HCNLoader.show(dispatch);
            ItemModel.updateItem(
                updatedRootCategoryItem,
                itemId,
                langCode
            )
                .then(() => {
                    HCNLoader.hide(dispatch);
                    history.push("/rootCategory-items");
                })
                .catch((err) => {
                    toast(err.message);
                    HCNLoader.hide(dispatch);
                });
        } else {
            rootCategoryItem.available = true;

            HCNLoader.show(dispatch);
            ItemModel.createItem(updatedRootCategoryItem)
                .then(() => {
                    HCNLoader.hide(dispatch);
                    history.push("/rootCategory-items");
                })
                .catch((err) => {
                    toast.error(
                        err && err.message
                            ? err.message
                            : "Something went wrong"
                    );
                    HCNLoader.hide(dispatch);
                });
        }
    };

    const handleTabChange = (newLangCode) => {
        history.push(`/add-rootCategory-item/${newLangCode}/${itemId.trim() || ""}`);
    };

    useEffect(() => {
        if (mainRef.current) mainRef.current.scrollIntoView();
    }, []);

    useEffect(() => {
        if (!editMode) {
            setRootCategoryItem(initialRootItemState);
        }
    }, [editMode, setRootCategoryItem]);

    // useEffect(() => {
    //     const fetchAllItemsByLocale = async () => {
    //         const codes = langs.map((l) => l.code);
    //         try {
    //             HCNLoader.show(dispatch);

    //             const promises = codes.map((c) =>
    //                 ItemModel.getRootCategoryItems(FilterModel.providerId, c)
    //             );
    //             const results = await Promise.allSettled(promises);
    //             const sanitized = results.reduce((acc, curr) => {
    //                 if (
    //                     curr.status === "fulfilled" &&
    //                     curr.value.length &&
    //                     curr.value[0].language_x
    //                 ) {
    //                     const { language_x: language } = curr.value[0];
    //                     acc[language]
    //                         ? acc[language].push(...curr.value)
    //                         : (acc[language] = curr.value);
    //                 } else return null;

    //                 return acc;
    //             }, {});
    //             console.log(sanitized);
    //         } catch (error) {
    //             toast(error.message, { type: "error" });
    //         } finally {
    //             HCNLoader.hide(dispatch);
    //         }
    //     };

    //     fetchAllItemsByLocale();
    // }, [langs, dispatch]);

    return (
        <div className="divider grid-flex">
            <SideBar />
            <div id="page" className="site" ref={mainRef}>
                <div className="main-content">
                    <header className="site-header">
                        <div className="flex-between">
                            <h1 className="main-title">
                                {editMode ? "Edit" : "Add New"} Item
                            </h1>
                            <div className="side-functionality">
                                <div className="side-button grid-flex">
                                    <button
                                        onClick={() =>
                                            history.push("/rootCategory-items")
                                        }
                                        type="submit"
                                        className="btn"
                                    >
                                        {getTranslatedText("cancel", store)}
                                    </button>
                                    <button
                                        onClick={createRootCategoryItem}
                                        type="submit"
                                        className="btn default-btn"
                                    >
                                        {getTranslatedText("save", store)}
                                    </button>
                                    <MainMenuBtn />
                                </div>
                            </div>
                        </div>
                    </header>
                    <main className="spacing">
                        {editMode ? (
                            <LangTabs
                                langs={langs}
                                handleTabChange={handleTabChange}
                                selectedCode={langCode}
                            >
                                <ItemForm
                                    isSynergyProvider={isSynergyProvider}
                                    providerId={providerId}
                                    itemId={itemId}
                                    langCode={langCode}
                                    editMode={editMode}
                                    rootCategoryItem={rootCategoryItem}
                                    setRootCategoryItem={setRootCategoryItem}
                                />
                            </LangTabs>
                        ) : (
                            <ItemForm
                                isSynergyProvider={isSynergyProvider}
                                providerId={providerId}
                                itemId={itemId}
                                langCode={langCode}
                                editMode={editMode}
                                rootCategoryItem={rootCategoryItem}
                                setRootCategoryItem={setRootCategoryItem}
                            />
                        )}
                    </main>
                </div>
            </div>
        </div>
    );
};

export default AddRootCategoryItem;
