// import FilterModel from "../../data/filter-model";
// import ItemModel from "../../data/item-model";
// import ModifierModel from "../../data/modifier-model";
import ProviderModel from "../../data/provider-model";
import Config from "../../Config";

export const SET_PROVIDER_ID = "provider/SET_PROVIDER_ID";
export const SET_PROVIDER = "provider/SET_PROVIDER";
export const SET_PROVIDER_LOCALES = "provider/SET_PROVIDER_LOCALES";
export const RESET_PROVIDER = "provider/RESET_PROVIDER";

export const FETCH_PROVIDER_DATA_REQUEST = "provider/FETCH_PROVIDER_DATA_REQUEST";
export const FETCH_PROVIDER_DATA_SUCCESS = "provider/FETCH_PROVIDER_DATA_SUCCESS";
export const FETCH_PROVIDER_DATA_ERROR = "provider/FETCH_PROVIDER_DATA_ERROR";

/**
 * 
 * @param {{name:string,code:string}[]} locales 
 * @returns 
 */
function transformLocalesResponse(locales) {
    const transformed = locales.sort((a, b) => a.name.localeCompare(b.name));

    const defaultIndex = transformed.findIndex(
        (l) => l.code === Config.DEFAULT_LANG_CODE
    );

    if (defaultIndex !== -1) {
        [transformed[0], transformed[defaultIndex]] = [
            transformed[defaultIndex],
            transformed[0],
        ];
    }

    return transformed;
}

export function setProviderId(providerId) {
    return {
        type: SET_PROVIDER_ID,
        payload: providerId,
    };
}

export function setProvider(provider) {
    return {
        type: SET_PROVIDER,
        payload: provider,
    };
}

export function setProviderLocales(locales) {
    return {
        type: SET_PROVIDER_LOCALES,
        payload: locales,
    };
}

export function resetProvider() {
    return {
        type: RESET_PROVIDER,
    };
}

function fetchProviderDataRequest() {
    return {
        type: FETCH_PROVIDER_DATA_REQUEST,
    };
}

function fetchProviderDataSuccess(provider) {
    return {
        type: FETCH_PROVIDER_DATA_SUCCESS,
        payload: provider,
    };
}

function fetchProviderDataError(error) {
    return {
        type: FETCH_PROVIDER_DATA_ERROR,
        payload: error,
    };
}

export function fetchProviderData(providerId) {
    return async (dispatch, getState) => {
        // TODO: uncomment rest promises when ready to make some serious changes
        const promises = [
            ProviderModel.getLocales(providerId),
            ProviderModel.remote_getOne(providerId),
            // FilterModel.remote_getAllById(providerId),
            // ModifierModel.getProviderModifiersById(providerId),
            // ModifierModel.remote_getAll(),
            // ItemModel.getRootCategoryItems(providerId),
        ];

        dispatch(fetchProviderDataRequest());

        try {
            const results = await Promise.allSettled(promises);

            results.forEach((result, index) => {
                if (result.status === "fulfilled") {
                    dispatch(fetchProviderDataSuccess(result.value));
                    switch (index) {
                        case 0:
                            dispatch(setProviderLocales(transformLocalesResponse(result.value)));
                            break;
                        case 1:
                            dispatch(setProviderId(result.value.id));
                            dispatch(setProvider(result.value));
                            break;
                        default:
                            break;
                    }
                } else {
                    dispatch(fetchProviderDataError(result.reason));
                    switch (index) {
                        case 0:
                            dispatch(setProviderLocales([]));
                            break;
                        case 1:
                            dispatch(setProviderId(null));
                            dispatch(setProvider(null));
                            break;
                        default:
                            break;
                    }
                }
            });
        } catch (error) {
            dispatch(resetProvider());
            dispatch(fetchProviderDataError(error.message));
        }
    };
}