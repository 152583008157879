import React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useParams, generatePath } from 'react-router-dom';
import { toast } from 'react-toastify';
import FilterModel from '../../data/filter-model';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';
import MainMenuBtn from '../menu-settings/main-menu-btn';
import SideBar from '../side-bar/side-bar';
import { LangTabs } from '../translations/language-tabs';
import { useDispatch, useSelector } from 'react-redux';
import HCNLoader from '../../helpers/hcn-loader';
import { selectProviderId, selectProviderLocales } from '../../redux/provider/selectors';

const FilterForm = ({ filterForm, setFilterForm, block, setBlock }) => {
    return (
        <form style={{ marginTop: 30 }}>
            <div className="info info-title" data-info="filter_name">
                <label htmlFor="filter_name">
                    {getTranslatedText("filterName", store)}
                    <sup>(*)</sup>
                </label>
                <input
                    id="filter_name"
                    name="filter_name"
                    value={filterForm.name}
                    onChange={(event) =>
                        setFilterForm({
                            ...filterForm,
                            name: event.target.value,
                        })
                    }
                    type="text"
                />
            </div>
            <div className="info info-title" data-info="filter_name">
                <label htmlFor="filter_name">
                    {getTranslatedText("acronym", store)}
                    <sup>(*)</sup>
                </label>
                <input
                    id="filter_name"
                    name="filter_name"
                    value={filterForm.acronym}
                    onChange={(event) => {
                        if (event.target.value.length > 2) {
                            if (!block) {
                                toast("The Acronym must be up to 2 letters");
                                setBlock(true);
                            }
                        } else {
                            setFilterForm({
                                ...filterForm,
                                acronym: event.target.value,
                            });
                            setBlock(false);
                        }
                    }}
                    type="text"
                />
            </div>
        </form>
    );
};

const AddFilter = props => {
    const history = useHistory();

    const providerId = useSelector(selectProviderId);
    const dispatch = useDispatch();
    const langs = useSelector(selectProviderLocales);
    const { filterId, langCode } = useParams();
    const [filterForm, setFilterForm] = useState({
        id: "",
        name: history.location.state?.filterName ?? "",
        acronym: history.location.state?.filterAcronym ?? "",
    });

    const [block, setBlock] = useState(false);

    const isClone =
        history.location.state?.filterName ||
        history.location.state?.filterAcronym;

    const headerText = `${
        isClone ? "Clone" : filterId ? "Edit" : "Add"
    } Filter`;

    useEffect(() => {
        const fetchFilter = async () => {
            const promise = providerId
                ? FilterModel.remote_getAllById(providerId, langCode)
                : FilterModel.remote_getAll();

            try {
                HCNLoader.show(dispatch);
                const allFilters = await promise;

                const filter = allFilters.find((item) => item.id === filterId);

                if (!filter) {
                    throw new Error("Filter not found");
                }

                setFilterForm((prev) => ({
                    ...prev,
                    id: filter?.id ?? prev.id,
                    name: filter?.name ?? prev.name,
                    acronym: filter?.acronym ?? prev.acronym,
                }));
            } catch (error) {
                console.log(error);
                toast.error(`Failed to retrieve filter with id: ${filterId}`, {
                    autoClose: 2000,
                });
                history.replace("/filters");
            } finally {
                HCNLoader.hide(dispatch);
            }
        };

        if (filterId) fetchFilter();
        else {
            setFilterForm({
                id: "",
                name: history.location.state?.filterName ?? "",
                acronym: history.location.state?.filterAcronym ?? "",
            });
        }
    }, [filterId, providerId, langCode, history, dispatch]);

    const onSubmitFilterForm = async (event) => {
        event.preventDefault();

        const name = history.location.state?.filterName;
        const acronym = history.location.state?.filterAcronym;

        if (!name && !acronym) {
            toast("Please provide a Name", {
                type: "error",
                autoClose: 2000,
            });
            return;
        }

        if (name === filterForm.name || acronym === filterForm.acronym) {
            toast.warn(getTranslatedText("cloneFiltersWarning", store));
            return;
        }

        const promise = providerId
            ? FilterModel.remote_getAllById(providerId, langCode)
            : FilterModel.remote_getAll();

        try {
            await promise;
            history.push("/filters");
        } catch (err) {
            toast(err.message, { autoClose: 2000 });
        }

        event.stopPropagation();
        setBlock(false);
    };

    const cancelAction = () => {
        setBlock(false)
        history.push('/filters');
    };

    const handleTabChange = (newCode) => {
        const path = generatePath("/add-filter/:filterId?/:langCode?", {
            filterId,
            langCode: newCode,
        });

        history.push(path);
    };

    return (
        <div className="provider-info">
            <div className="divider grid-flex">
                <SideBar />
                <div id="page" className="site">
                    <div className="main-content">
                        <header className="site-header">
                            <div className="flex-between">
                                <h1 className="main-title">
                                    {/* {getTranslatedText("addFilter", store)} */}
                                    {headerText}
                                </h1>
                                <div className="side-functionality">
                                    <div className="side-button grid-flex">
                                        <button
                                            onClick={cancelAction}
                                            type="button"
                                            className="btn"
                                        >
                                            {getTranslatedText("cancel", store)}
                                        </button>
                                        <button
                                            onClick={onSubmitFilterForm}
                                            type="submit"
                                            className="btn default-btn"
                                        >
                                            {getTranslatedText("save", store)}
                                        </button>
                                        <MainMenuBtn />
                                    </div>
                                </div>
                            </div>
                        </header>
                        <main className="spacing">
                            {providerId && filterId ? (
                                <LangTabs
                                    selectedCode={langCode}
                                    langs={langs}
                                    handleTabChange={handleTabChange}
                                >
                                    <FilterForm
                                        filterForm={filterForm}
                                        setFilterForm={setFilterForm}
                                        block={block}
                                        setBlock={setBlock}
                                    />
                                </LangTabs>
                            ) : (
                                <FilterForm
                                    filterForm={filterForm}
                                    setFilterForm={setFilterForm}
                                    block={block}
                                    setBlock={setBlock}
                                />
                            )}
                        </main>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddFilter;
