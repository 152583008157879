import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ModifierModel from '../../data/modifier-model';
import MainMenuBtn from '../menu-settings/main-menu-btn';
import SideBar from '../side-bar/side-bar';
import Modifier from './modifier';
import { useDispatch, useSelector } from 'react-redux';
import HCNLoader from '../../helpers/hcn-loader';
import { toast } from 'react-toastify';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';
import { selectProviderId } from '../../redux/provider/selectors';
import { useStateSafe } from '../../hooks';

const Modifiers = props => {
    const [modifiers, setModifiers] = useStateSafe([]);
    const providerId = useSelector(selectProviderId);

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        HCNLoader.show(dispatch);

        providerId
            ? ModifierModel.getProviderModifiersById(providerId)
                  .then((modifiers) => {
                      if (modifiers.length === 0) {
                          history.push("/import-default-modifiers");
                      } else {
                          setModifiers(modifiers);
                      }
                      setTimeout(() => {
                          HCNLoader.hide(dispatch);
                      }, 1000);
                  })
                  .catch((err) => {
                      toast(err.message);
                      HCNLoader.hide(dispatch);
                  })
            : ModifierModel.remote_getAll()
                  .then((modifiers) => {
                      if (modifiers.length === 0) {
                          history.push("/add-modifier");
                      } else {
                          setModifiers(modifiers);
                      }
                      setTimeout(() => {
                          HCNLoader.hide(dispatch);
                      }, 1000);
                  })
                  .catch((err) => {
                      toast(err.message);
                      HCNLoader.hide(dispatch);
                  });
        return () => {
            setModifiers([]);
        };
    }, [providerId, dispatch, history, setModifiers]);

    return (
        <div className="divider grid-flex">
            <SideBar />
            <div id="page" className="site">
                <div className="main-content">
                    <header className='site-header'>
                        <div className="flex-between">
                            <h1 className="main-title">
                                {getTranslatedText('modifiers', store)}
                            </h1>
                            <div className="side-functionality">
                                <div className="side-button grid-flex">
                                    {providerId ? <><button onClick={() => {
                                        history.push('/import-default-modifiers');
                                    }} className="btn default-btn">
                                        {getTranslatedText('importModifier', store)}
                                    </button>
                                        <button
                                            onClick={() => {
                                                history.push('/add-modifier');
                                            }}
                                            className="btn default-btn">
                                            {getTranslatedText('addModifier', store)}
                                        </button></>
                                        : <button onClick={() => {
                                            history.push('/add-modifier');
                                        }} className="btn default-btn">
                                            {getTranslatedText('addModifier', store)}
                                        </button>}
                                    <MainMenuBtn />
                                </div>
                            </div>

                        </div>
                    </header>
                    <main>
                        {modifiers && modifiers.sort().map((modifier, index) =>
                            <Modifier modifiers={modifiers} setModifiers={setModifiers} key={index} modifier={modifier} />,
                        )}
                    </main>
                </div>
            </div>
        </div>
    );
};

export default Modifiers;
