import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import FilterModel from "../../../data/filter-model";
import { getTranslatedText } from "../../../helpers/checkTranslations";
import { store } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { selectProviderId} from "../../../redux/provider/selectors";
import { resetProvider } from "../../../redux/provider/actions";
import { fetchProviderData } from "../../../redux/provider/actions";

const SidebarProvider = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const isAdmin = useSelector((state) => state.Reducer.isAdmin);
    const providerId = useSelector(selectProviderId);

    const goTo = () => {
        history.push(isAdmin ? "/" : "/providers");
        dispatch(resetProvider());
    };

    useEffect(() => {
        // TODO: leave this for now, not really a good spot to fetch. This sidebar is everywhere
        if (providerId) {
            dispatch(fetchProviderData(providerId));
        }
    }, [providerId, dispatch]);

    return (
        <>
            <div>
                <p>{getTranslatedText("provider", store)}</p>
                <h3>{FilterModel.provider.name}</h3>
            </div>
            <button onClick={goTo} className="btn add-btn">
                <span>
                    <img src={"/img/svg/arrow_right.svg"} alt="next" />
                </span>
            </button>
        </>
    );
};

export default SidebarProvider;
