import React, { useRef } from "react";
import { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useHistory, useParams } from "react-router-dom";
import ProviderModel from "../../data/provider-model";
import MainMenuBtn from "../menu-settings/main-menu-btn";
import SideBar from "../side-bar/side-bar";
import FirstTab from "./tabs/FirstTab";
import SecondTab from "./tabs/secondTab";
import Tabs from "./tabs/tabs";
import { useDispatch } from "react-redux";
import HCNLoader from "../../helpers/hcn-loader";
import { ToastContainer, toast } from "react-toastify";
import { getTranslatedText } from "../../helpers/checkTranslations";
import { store } from "../../redux/store";
import Config from "../../Config";
import { validateEmails } from "../../utils/functions";

const providerDefaultData = {
    id: undefined,
    name: "",
    info: "",
    teaser: "",
    type: -1,
    updated: "",
    images: [],
    timezone: "",
    deliveryTime: 15,
    communicationType: 0,
    tips: [],
    fees: {
        fees: {
            fees: [],
            taxes: [],
            extraTaxes: [],
        },
    },
    deviceToken: "",
    providerType: Config.getDineIn() ? 1 : 2,
    meta: {},
};

const ProviderInfo = (props) => {
    const history = useHistory();
    const { providerId } = useParams();
    const dispatch = useDispatch();

    const [providerForm, setProviderForm] = useState(providerDefaultData);
    const [activeTab, setActiveTab] = useState("tab1");
    const [modified, setModified] = useState(false);
    const actionText = providerId ? "Edit" : "Add";

    const first = useRef(null);

    const addProvider = (providerForm) => {
        if (modified) {
            HCNLoader.show(dispatch);
            ProviderModel.remote_addOne({
                ...providerForm,
            })
                .then(() => {
                    HCNLoader.hide(dispatch);
                    history.push("/providers");
                })
                .catch((err) => {
                    toast(err.message);
                    HCNLoader.hide(dispatch);
                    toast.error(getTranslatedText("addAllFields", store));
                });
        } else {
            toast.error(getTranslatedText("changeBeforeSave", store));
        }
    };

    const upDateProvider = (providerForm) => {
        if (
            providerForm.name !== "" &&
            providerForm.type !== "" &&
            providerForm.teaser !== "" &&
            providerForm.timezone !== "" &&
            providerForm.deliveryTime !== "" &&
            providerForm.info !== "" &&
            providerForm.fees.fees?.length > 0
        ) {
            HCNLoader.show(dispatch);
            ProviderModel.remote_editOne({
                ...providerForm,
                providerType: Config.getDineIn() ? 1 : 2,
            })
                .then(() => {
                    HCNLoader.hide(dispatch);
                    history.push("/providers");
                })
                .catch((err) => {
                    toast(err.message);
                    HCNLoader.hide(dispatch);
                    toast.error(getTranslatedText("addAllFields", store));
                });
        } else {
            toast.error(getTranslatedText("addAllFields", store));
        }
    };

    const onSubmitForm = () => {
        let emails;
        if (providerForm.communicationType === 2) {
            if (!providerForm.meta.email || providerForm.meta.email === "") {
                toast(
                    getTranslatedText(
                        "providerInfoSaveWarningWithEmail",
                        store
                    ),
                    {
                        autoClose: 4000,
                    }
                );
                return;
            }

            emails = validateEmails(providerForm.meta.email);
            if (!emails) {
                toast(
                    getTranslatedText(
                        "providerInfoSaveWarningWithEmail",
                        store
                    ),
                    {
                        autoClose: 4000,
                    }
                );
                return;
            }
        }

        if (
            providerForm.name !== "" &&
            providerForm.type !== "" &&
            providerForm.teaser !== "" &&
            providerForm.timezone !== "" &&
            providerForm.deliveryTime !== "" &&
            providerForm.info !== "" &&
            (providerForm.images?.[0]?.default ||
                providerForm.images?.[0]?.main ||
                providerForm.images?.[0]?.thumbnail) &&
            providerForm.fees.fees
        ) {
            providerForm.active = true;
            providerForm.id
                ? upDateProvider({
                      ...providerForm,
                      name: String(providerForm.name)
                          .toLowerCase()
                          .split(" ")
                          .map(
                              (s) => s.charAt(0).toUpperCase() + s.substring(1)
                          )
                          .join(" "),
                      providerType: Config.getDineIn() ? 1 : 2,
                      email: emails,
                  })
                : addProvider({
                      ...providerForm,
                      meta:
                          Object.keys(providerForm.meta).length === 0
                              ? {
                                    paymentRequired: false,
                                    selfDelivered: false,
                                    ...providerForm.meta,
                                }
                              : !providerForm.meta.paymentRequired &&
                                providerForm.meta.selfDelivered
                              ? {
                                    paymentRequired: false,
                                    selfDelivered:
                                        providerForm.meta.selfDelivered,
                                    ...providerForm.meta,
                                }
                              : providerForm.meta.paymentRequired &&
                                !providerForm.meta.selfDelivered
                              ? {
                                    paymentRequired:
                                        providerForm.meta.paymentRequired,
                                    selfDelivered: false,
                                    ...providerForm.meta,
                                }
                              : {
                                    paymentRequired:
                                        providerForm.meta.paymentRequired,
                                    selfDelivered:
                                        providerForm.meta.selfDelivered,
                                    ...providerForm.meta,
                                },
                      name: String(providerForm.name)
                          .toLowerCase()
                          .split(" ")
                          .map(
                              (s) => s.charAt(0).toUpperCase() + s.substring(1)
                          )
                          .join(" "),
                      providerType: Config.getDineIn() ? 1 : 2,
                      email: emails,
                  });
        } else {
            toast(getTranslatedText("providerInfoSaveWarning", store), {
                autoClose: 4000,
            });
        }
    };

    const cancelAction = () => {
        history.push("/providers");
    };

    const renderTab = () => {
        return activeTab === "tab1" ? (
            <FirstTab
                providerForm={providerForm}
                setProviderForm={setProviderForm}
                setModified={setModified}
            />
        ) : (
            <SecondTab
                providerForm={providerForm}
                setProviderForm={setProviderForm}
            />
        );
    };

    useEffect(() => {
        first.current.scrollIntoView();
    }, []);

    useEffect(() => {
        let timeoutId;
        const fetchProviderData = async () => {
            try {
                HCNLoader.show(dispatch);

                const promises = [ProviderModel.remote_getOne(providerId)]; // maybe fetch locales here too

                const results = await Promise.allSettled(promises);

                results.forEach((result, index) => {
                    if (result.status === "fulfilled") {
                        switch (index) {
                            case 0: {
                                const res = result.value;
                                setProviderForm({
                                    ...res,
                                    id: res.id ? res.id : undefined,
                                    name: res.name ?? "",
                                    info: res.info ?? "",
                                    teaser: res.teaser ?? "",
                                    images: res.images ?? [],
                                    type: res.type ?? 0,
                                    fees: res.fees ?? {},
                                    timezone: res.timezone ?? "",
                                    deliveryTime: res.deliveryTime ?? "",
                                    tips: res.tips ?? [],
                                    placeSettings: res.placeSettings ?? false,
                                    communicationType:
                                        res.communicationType ?? 0,
                                    // todo
                                    meta: res.meta || [],
                                    deviceToken: res.deviceToken,
                                    providerType: Config.getDineIn() ? 1 : 2,
                                });
                                break;
                            }
                            default:
                                break;
                        }
                    } else {
                        switch (index) {
                            case 0:
                                setProviderForm(providerDefaultData);
                                toast.error(
                                    "Failed retrieving provider with id " +
                                        providerId
                                );
                                break;
                            default:
                                break;
                        }
                    }
                });

                timeoutId = setTimeout(() => setModified(false), 100);
            } catch (error) {
                toast(error.message);
            } finally {
                HCNLoader.hide(dispatch);
            }
        };

        if (providerId) {
            fetchProviderData();
        } else {
            setProviderForm(providerDefaultData);
            timeoutId = setTimeout(() => setModified(false), 100);
        }

        return () => {
            window.clearTimeout(timeoutId);
        };
    }, [providerId, dispatch]);

    useEffect(() => {
        let timeoutId;
        if (!providerId) {
            setProviderForm(providerDefaultData);
            timeoutId = setTimeout(() => setModified(false), 100);
        }

        return () => {
            window.clearTimeout(timeoutId);
        };
    }, [providerId]);

    return (
        <div ref={first}>
            <ToastContainer />
            <div className="modal-layer"></div>
            <div className="divider grid-flex">
                <SideBar />
                <div id="page" className="site">
                    <div className="main-content">
                        <div>
                            <header className="site-header">
                                <div className="flex-between">
                                    <h1 className="main-title">
                                        {actionText}{" "}
                                        {getTranslatedText("provider", store)}
                                    </h1>
                                    <div className="side-functionality">
                                        <div className="side-button grid-flex">
                                            <button
                                                onClick={cancelAction}
                                                type="button"
                                                className="btn"
                                            >
                                                {getTranslatedText(
                                                    "cancel",
                                                    store
                                                )}
                                            </button>
                                            <button
                                                onClick={onSubmitForm}
                                                className="btn default-btn"
                                            >
                                                {getTranslatedText(
                                                    "save",
                                                    store
                                                )}
                                            </button>
                                            <MainMenuBtn />
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <main>
                                <Tabs
                                    setActiveTab={setActiveTab}
                                    activeTab={activeTab}
                                />
                                {renderTab()}
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProviderInfo;
