import React, { useEffect, useRef, useState } from 'react';
import './provider-groups.css';
import SideBar from '../side-bar/side-bar';
import MainMenuBtn from '../menu-settings/main-menu-btn';
import { default as plus } from '../side-bar/plus-sign.svg';
import { toast } from 'react-toastify';
import ProviderModel from '../../data/provider-model';
import HotelModel from '../../data/hotel-model';
import { useDispatch } from 'react-redux';
import Config from '../../Config';
import ChooseProviders from './choose-providers';
import ReactModal from 'react-modal';
import HCNImages from '../../helpers/hcn-images';
import InfoModal from '../info-modal';
import { useHistory } from 'react-router-dom';
import DropZone from '../drop-zone/drop-zone';
import { v4 as uuidv4 } from 'uuid';
import HCNLoader from '../../helpers/hcn-loader';
import UploadModel from '../../data/upload-model';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';


const customStyles = {
    content: {
        top: '0',
        left: 'auto',
        right: '0',
        bottom: 'auto',
        width: '500px',
        backgroundColor: 'black',
    },
};

const AddProviderGroups = () => {
    const [providers, setProviders] = useState(null);
    const [hotels, setHotels] = useState(null);
    const [title, setTitle] = useState('');
    const [photo, setPhoto] = useState(null);
    const [groupName, setGroupName] = useState(null);
    const [edited, setEdited] = useState(false);

    const [restGroups, setRestGroups] = useState(null)

    const [selectedhotel, setSelectedHotel] = useState(null)
    const [rows, setRows] = useState([[1, 1, 1]])
    const [open, setOpen] = useState(false);
    const [coords, setCoords] = useState(null)

    const [show, setShow] = useState(false);
    const [alertPressed, setAlertPressed] = useState(false);
    const [infoMessage, setInfoMessage] = useState({
        message: '',
        confirmation: false,
    });

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch({
            type: Config.REDUCER_TYPES.SET_LOADING,
            payload: true,
        });

        ProviderModel.getProviderGroups()
            .then(res => {
                setRestGroups(res)

                dispatch({
                    type: Config.REDUCER_TYPES.SET_LOADING,
                    payload: false,
                });
            })
            .catch(err => {
                toast(err.message);
                dispatch({
                    type: Config.REDUCER_TYPES.SET_LOADING,
                    payload: false,
                });
            });

        ProviderModel.remote_getAll()
            .then(res => {
                setProviders(res.filter(x => Config.getDineIn() ? x.providerType === 1 : x.providerType === 2));
                dispatch({
                    type: Config.REDUCER_TYPES.SET_LOADING,
                    payload: false,
                });
            })
            .catch(err => {
                toast(err.message);
                dispatch({
                    type: Config.REDUCER_TYPES.SET_LOADING,
                    payload: false,
                });
            });

        HotelModel.remote_getAll()
            .then(res => {
                res && res.data &&
                    setHotels(res.data);
                dispatch({
                    type: Config.REDUCER_TYPES.SET_LOADING,
                    payload: false,
                });
            })
            .catch(err => {
                toast(err.message);
                dispatch({
                    type: Config.REDUCER_TYPES.SET_LOADING,
                    payload: false,
                });
            });


        return () => {
            setProviders([]);
            setHotels([])
        };
    }, []);

    useEffect(() => {
        if (infoMessage.confirmation && coords.in === 4) {
            setRows(rows.filter((i, idx) => idx !== coords.out))
        } else if (infoMessage.confirmation && coords.in < 3) {
            rows[coords.out][coords.in] = 1
        }
        return () => {
            setCoords(null)
            setInfoMessage({});
        };
    }, [infoMessage.confirmation]);

    useEffect(() => {
        if (hotels && hotels.filter(item =>
            item.id !==
            restGroups?.find(it =>
                it.hotelId === item.id)?.hotelId)?.length === 0) {
            history.push('provider-group-info')
            toast(getTranslatedText('noHotelsLeftWarning', store))
        }

    }, [hotels])

    const addRow = () => {
        if (rows?.[rows.length - 1]?.[0] === 1) {
            !alertPressed && toast.error(getTranslatedText('newRowGroupWarning', store))
            !alertPressed && setAlertPressed(true)
        } else {
            setRows(rows.concat([[1, 1, 1]]))
            setAlertPressed(false)
        }
    };

    const openModal = (idx, id) => {
        setOpen(true);
        setCoords({ out: idx, in: id })
    };

    const closeModal = () => {
        setOpen(false);
        setCoords(null)
    };

    const hideModal = () => {
        setShow(false);
    };

    const saveProviderGroup = async () => {
        if (groupName && title && photo && selectedhotel && rows && rows.length > 0) {

            dispatch({
                type: Config.REDUCER_TYPES.SET_LOADING,
                payload: true,
            });
            const selected_providers = rows.map(it => it.filter(item => item !== 1).map(i => i.id));

            if (rows.length === 1) {
                if (rows[rows.length - 1][0] === 1) {
                    toast.error(getTranslatedText('addOneProviderWarning', store))

                } else {
                    const body = { name: groupName, hotelId: selectedhotel, providers: selected_providers, title, photo }
                    try {
                        const create = await ProviderModel.createProviderGroups(body)
                        create && history.push('/provider-group-info')
                        dispatch({
                            type: Config.REDUCER_TYPES.SET_LOADING,
                            payload: false,
                        });
                    } catch (err) {
                        toast(err.message)
                        dispatch({
                            type: Config.REDUCER_TYPES.SET_LOADING,
                            payload: false,
                        });
                    }
                }
            } else if (rows.length > 1) {
                if (rows.some((item, idx) => idx < rows.length - 1 && item[1] === 1)) {
                    toast.error(getTranslatedText('addTwoProvidersInRow', store))
                    dispatch({
                        type: Config.REDUCER_TYPES.SET_LOADING,
                        payload: false,
                    });
                } else {
                    const body = { name: groupName, hotelId: selectedhotel, providers: selected_providers, title, photo }
                    try {
                        const create = await ProviderModel.createProviderGroups(body)
                        dispatch({
                            type: Config.REDUCER_TYPES.SET_LOADING,
                            payload: false,
                        });
                        create && history.push('/provider-group-info')
                    } catch (err) {
                        toast(err.message)
                        dispatch({
                            type: Config.REDUCER_TYPES.SET_LOADING,
                            payload: false,
                        });
                    }
                }
            }
            dispatch({
                type: Config.REDUCER_TYPES.SET_LOADING,
                payload: false,
            });
        } else {
            toast(getTranslatedText('addAllFields', store))
        }
    }

    const cancelAction = () => {
        history.push('/provider-group-info');
    }

    const removeImage = (idx, id) => {
        setShow(true);
        setInfoMessage({
            ...infoMessage,
            message: `${getTranslatedText('deleteProviderFromRow', store)} ${idx + 1}?`,
        });
        setCoords({ out: idx, in: id })
    }

    const enableInput = () => {
        setEdited(!edited);
    };

    const deleteRow = (id) => {
        setShow(true);
        setInfoMessage({
            ...infoMessage,
            message: `${getTranslatedText('deleteRow', store)} ${id + 1}?`,
        });
        setCoords({ out: id, in: 4 })
    }

    const onFilesUpload = (files) => {
        if (files && files.length > 0) {
            console.log({ files });

            const formData = new FormData();
            let filename = `${uuidv4()}.${files[0].name.split('.').pop()}`;
            formData.append(
                'file',
                files[0],
                filename,
            );
            formData.append(
                'name',
                filename,
            );

            HCNLoader.show(dispatch);
            UploadModel
                .upload(formData)
                .then(() => {
                    HCNLoader.hide(dispatch);
                    setPhoto(filename)
                })
                .catch(err => {
                    toast(err.message);
                    HCNLoader.hide(dispatch);
                });

        } else {
            console.log('err');
        }
    };

    return (
        <div className="divider grid-flex">
            <InfoModal
                setInfoMessage={setInfoMessage}
                infoMessage={infoMessage}
                hideModal={hideModal}
                show={show}
            />
            <ReactModal
                isOpen={open}
                ariaHideApp={false}
                style={customStyles}>
                <ChooseProviders open={open} coords={coords} rows={rows} setRows={setRows} providers={providers} closeModal={closeModal} />
            </ReactModal>
            <SideBar />
            <div id="page" className="site">
                <div className="main-content">
                    <header className='site-header'>
                        <div className="flex-between">
                            <div>
                                <h1 className="main-title">
                                    <div className="info info-title" data-info="item_name">
                                        <input
                                            id="item_name"
                                            disabled={!edited}
                                            name="item_name"
                                            placeholder={getTranslatedText('groupName', store)}
                                            value={groupName ?? ''}
                                            onChange={({ target: { value } }) => setGroupName(value)}
                                        />
                                    </div>
                                </h1>
                                <button type="button" onClick={enableInput} className="edit-title">
                                    {getTranslatedText('edit', store)}
                                </button>
                            </div>
                            <div className="side-functionality">
                                <div className="side-button grid-flex">
                                    <button onClick={cancelAction} type='button' className="btn">{getTranslatedText('cancel', store)}</button>
                                    <button onClick={saveProviderGroup} className="btn default-btn">{getTranslatedText('save', store)}</button>
                                    <MainMenuBtn />
                                </div>
                            </div>
                        </div>
                    </header>
                    <main>

                        <div className='time-settings'>
                            <div className="info" style={{ width: 190 }} data-info="rest_groups_title">
                                <label htmlFor="provider_timezone" >
                                    {getTranslatedText('title', store)}
                                    <sup>*</sup>
                                </label>
                                <input
                                    id="rest_groups_title"
                                    name="title"
                                    type="text"
                                    value={title}
                                    autoFocus
                                    min={0}
                                    max={20}
                                    onChange={({ target: { value } }) => {
                                        setTitle(value)
                                    }}
                                />
                            </div>
                            {!photo
                                ? <DropZone
                                    accepted_mime_types={'image/gif,image/jpeg,image/png,image/jpg'}
                                    text={getTranslatedText('dragDrop', store)}
                                    multiple={false}
                                    onFilesUpload={files => onFilesUpload(files)}
                                />
                                : <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <img width={200} src={`${Config.getS3BucketPrefix()}/${photo}`} alt='xaxa' />
                                    <button className="remove margin-left-auto" onClick={() => setPhoto(null)}>
                                        <img src="../img/svg/trash.svg" alt="double item" />
                                    </button>
                                </div>
                            }
                            <div className='time-option'>
                                <span className='sub-title-2'>
                                    {getTranslatedText('selectHotel', store)}
                                </span>
                                <select
                                    className='margin-top select-hotel'
                                    value={selectedhotel?.name}
                                    placeholder="Choose a hotel"
                                    onChange={event => {
                                        setSelectedHotel(Number(event.target.value))
                                    }}>
                                    <option value={''} key={'default'}></option>
                                    {
                                        hotels && hotels.filter(item =>
                                            item.id !==
                                            restGroups?.find(it =>
                                                it.hotelId === item.id)?.hotelId).map((item, idx) =>
                                                    <option value={item.id} key={item.id}>{item.name}
                                                    </option>,
                                                )
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="section-head flex-end border-bottom margin-top-20">
                            <button onClick={addRow} type="button" className="default-btn margin-bottom-20">
                                {getTranslatedText('addRow', store)}
                            </button>
                        </div>
                        {rows.map((row, idx) =>
                            <div key={idx} className='grid-flex border-bottom margin-bottom-20'>
                                {row.map((item, index) =>
                                    <div key={index} className=''>
                                        {index === 0 && <div className='box-plus-groups'>
                                            <div className='margin-left-auto pointer'>
                                                <div onClick={() => removeImage(idx, index)}>{item !== 1 ? 'x' : null}</div>
                                            </div>
                                            {item === 1 ? <img className='pointer' height={50} onClick={item === 1 ? () => openModal(idx, index) : () => { }}
                                                src={plus} width="100" alt="add"
                                            />
                                                : <div className='flex-column-between-center'>
                                                    <img className='pointer' width={100} onClick={item === 1 ? () => openModal(idx, index) : () => { }}
                                                        src={HCNImages.getFullImageURL(item.images[0])} alt="add"
                                                    />
                                                    <span className='margin-top-20 text-align-center'>{item.name}</span>
                                                </div>
                                            }
                                            <span> {item === 1 ? getTranslatedText('addProvider', store) : null}</span>
                                        </div>}

                                        {index === 1 && idx < rows.length - 1 && <div className='box-plus-groups'>
                                            <div className='margin-left-auto pointer'>
                                                <div onClick={() => removeImage(idx, index)}>{item !== 1 ? 'x' : null}</div>
                                            </div>
                                            {item === 1 ? <img className='pointer' height={50} onClick={item === 1 ? () => openModal(idx, index) : () => { }}
                                                src={plus} width="100" alt="add"
                                            />
                                                : <div className='flex-column-between-center'>
                                                    <img className='pointer' width={100} onClick={item === 1 ? () => openModal(idx, index) : () => { }}
                                                        src={HCNImages.getFullImageURL(item.images[0])} alt="add"
                                                    />
                                                    <span className='margin-top-20 text-align-center'>{item.name}</span>
                                                </div>}
                                            <span> {item === 1 ? getTranslatedText('addProvider', store) : null}</span>
                                        </div>}

                                        {index === 1 && idx === rows.length - 1 && <div className='box-plus-groups'>
                                            <div className={item === 1 && idx === rows.length - 1 ? 'color-orange' : 'margin-left-auto pointer'}>
                                                <div onClick={() => item === 1 ? () => { } : removeImage(idx, index)}>{item === 1 && idx === rows.length - 1 ? getTranslatedText('optional', store) : 'x'}</div>
                                            </div>
                                            {item === 1 ? <img className='pointer' height={50} onClick={item === 1 ? () => openModal(idx, index) : () => { }}
                                                src={plus} width="100" alt="add"
                                            />
                                                : <div className='flex-column-between-center'>
                                                    <img className='pointer' width={100} onClick={item === 1 ? () => openModal(idx, index) : () => { }}
                                                        src={HCNImages.getFullImageURL(item.images[0])} alt="add"
                                                    />
                                                    <span className='margin-top-20 text-align-center'>{item.name}</span>
                                                </div>}
                                            <span> {item === 1 ? getTranslatedText('addProvider', store) : null}</span>
                                        </div>}

                                        {index === 2 && <div className='box-plus-groups'>
                                            <div className={item === 1 ? 'color-orange' : 'margin-left-auto pointer'}>
                                                <div onClick={() => item === 1 ? () => { } : removeImage(idx, index)}>{item === 1 ? getTranslatedText('optional', store) : 'x'}</div>
                                            </div>
                                            {item === 1 ? <img className='pointer' height={50} onClick={item === 1 ? () => openModal(idx, index) : () => { }}
                                                src={plus} width="100" alt="add"
                                            />
                                                : <div className='flex-column-between-center'>
                                                    <img className='pointer' width={100} onClick={item === 1 ? () => openModal(idx, index) : () => { }}
                                                        src={HCNImages.getFullImageURL(item.images[0])} alt="add"
                                                    />
                                                    <span className='margin-top-20 text-align-center'>{item.name}</span>
                                                </div>}
                                            <span> {item === 1 ? getTranslatedText('addProvider', store) : null}</span>
                                        </div>}
                                    </div>,
                                )}
                                {idx !== 0 && <button onClick={() => deleteRow(idx)} className="remove margin-left-auto">
                                    <img src="../img/svg/trash.svg" alt="double item" />
                                </button>}
                            </div>,
                        )
                        }
                    </main>
                </div>
            </div>
        </div>
    );
};

export default AddProviderGroups;
