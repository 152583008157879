import React, { Component, useCallback } from 'react';
import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import RootCategoryModel from '../../data/rootCategory-model';
import FeatureSectionItem from './feature-section-item';
import RootCategoryItemsModal from './rootCategory-items-modal';
import './rootCategory.css';
import HCNLoader from '../../helpers/hcn-loader';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';
const customStyles = {
    content: {
        top: '0',
        left: 'auto',
        right: '0',
        bottom: 'auto',
        width: '500px',
        backgroundColor: 'black',
    },
};

const FeatureSection = ({ rootCategorySection, rootCategorySections, setRootCategorySection, setRootCategorySections }) => {
    const [edit, setEdit] = useState(false);
    const [featureSection, setFeatureSection] = useState({
        name: rootCategorySection ? rootCategorySection.name : '',
        id: rootCategorySection ? rootCategorySection.id : undefined,
        rootCategoryId: rootCategorySection ? rootCategorySection.rootCategoryId : undefined,
    });
    const [featureSectionItems, setFeatureSectionItems] = useState([]);
    const [show, setShow] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        rootCategorySection ? setFeatureSection({
            name: rootCategorySection ? rootCategorySection.name : '',
            id: rootCategorySection ? rootCategorySection.id : undefined,
            rootCategoryId: rootCategorySection ? rootCategorySection.rootCategoryId : undefined,
        }) : setFeatureSection(null)
    }, [rootCategorySection]);

    useEffect(() => {
        if (rootCategorySection.id) {
            HCNLoader.show(dispatch);
            RootCategoryModel.getRootCategorySectionItems(rootCategorySection.id).then(res => {
                setFeatureSectionItems(res);
                HCNLoader.hide(dispatch);
            });
        }
        rootCategorySection.id ? setEdit(true) : setEdit(false);
    }, [rootCategorySection, show]);

    const saveSection = () => {
        HCNLoader.show(dispatch);
        RootCategoryModel.createRootCategorySection(featureSection).then(res => {
            setRootCategorySections(rootCategorySections.concat(res));
            setRootCategorySection({ ...res });
            HCNLoader.hide(dispatch);
        })
            .catch(err => {
                toast(err.message)
                HCNLoader.hide(dispatch);
            })
    };

    const updateSection = () => {
        let ftsect = {
            name: featureSection.name,
            description: '',
        };
        HCNLoader.show(dispatch);
        RootCategoryModel.updateRootCategorySection(featureSection.id, ftsect)
            .then(res => {
                setRootCategorySections(rootCategorySections.map(item => {
                    if (item.id === res.id) {
                        return res;
                    } else {
                        return item;
                    }
                }));
                setRootCategorySection({ ...res });
                HCNLoader.hide(dispatch);
            })
            .catch(err => {
                toast(err.message);
                HCNLoader.hide(dispatch);
            });
    };

    const selectSectionItems = () => {
        setShow(true);
    };

    const closeModal = useCallback(() => {
        setShow(false);
    }, []);

    const allowEdit = () => {
        setEdit(!edit);
    };

    return (
        <div className="tab-windows options-wrapper active">
            <ReactModal
                isOpen={show}
                ariaHideApp={false}
                style={customStyles}>
                <RootCategoryItemsModal
                    show={show}
                    featureSectionItems={featureSectionItems}
                    setFeatureSectionItems={setFeatureSectionItems}
                    featureSection={featureSection}
                    closeModal={closeModal}
                />
            </ReactModal>
            <div className="flex-between">
                <div className="flex-center">
                    <div>
                        <h3 className="category-name">
                            <input
                                className='section-name'
                                id="item_name"
                                name="item_name"
                                placeholder='name of section'
                                disabled={edit}
                                value={featureSection.name}
                                onChange={event =>
                                    setFeatureSection({
                                        ...featureSection,
                                        name: event.target.value,
                                    })
                                }
                            />
                            {/* <span className="sub-title count margin-left"><span className="items-count">3</span> Items</span> */}
                        </h3>
                        <button type="button" onClick={allowEdit} className="edit-title">
                            {getTranslatedText('edit', store)}
                        </button>
                    </div>
                </div>
                {featureSection.id ? !edit ? <button
                    onClick={updateSection}
                    type="button" className="btn default-btn"
                    data-type="select-item-modal"
                    data-attribute="modal-action">{getTranslatedText('updateItem', store)}</button> : <button
                        onClick={selectSectionItems}
                        type="button" className="btn default-btn"
                        data-type="select-item-modal"
                        data-attribute="modal-action">{getTranslatedText('selectItems', store)}</button> :
                    <button onClick={saveSection} type="button"
                        className="btn default-btn" data-type="select-item-modal"
                        data-attribute="modal-action">{getTranslatedText('saveSection', store)}</button>

                }
            </div>

            <div className="grid has-col-15 border-bottom padding-top-double padding-bottom">
                <div className="grid-item custom-grid-1-1 grid-start">
                </div>
                <div className="grid-item custom-grid-2-4">
                    <h3 className="clearfix">{getTranslatedText('rootCategoryItem', store)}</h3>
                </div>
                <div className="grid-item custom-grid-3-2">
                    <p className="mods-filter-opt text-center">{getTranslatedText('filter', store)}</p>
                </div>
                <div className="grid-item custom-grid-4-2">
                    <p className="text-center">{getTranslatedText('price', store)}</p>
                </div>
                <div className="grid-item custom-grid-5-2">
                    <p className="text-center">{getTranslatedText('availability', store)}</p>
                </div>
                <div className="grid-item custom-grid-6-1">

                </div>
            </div>
            {featureSectionItems && featureSectionItems.map((sectionItem, index) =>
                <FeatureSectionItem
                    key={index + 'sectItem'}
                    rootCategorySection={rootCategorySection}
                    featureSectionItems={featureSectionItems}
                    setFeatureSectionItems={setFeatureSectionItems}
                    sectionItem={sectionItem}
                />,
            )}
        </div>
    );
};
export default FeatureSection;
