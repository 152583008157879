import Config from '../Config';
import Services from './services';
import LocalData from '../helpers/local-data';

const FilterModel = {
    _providerId: '',
    set providerId(restId) {
        LocalData.set(Config.LOCAL_DATA_KEYS.PROVIDER_ID, restId);
        this._providerId = restId;
    },
    get providerId() {
        return this._providerId ? this._providerId : LocalData.get(Config.LOCAL_DATA_KEYS.PROVIDER_ID, undefined);
    },
    _filterIdHCN: '',
    set filterIdHCN(restId) {
        this._filterIdHCN = restId;
    },
    get filterIdHCN() {
        return this._filterIdHCN;
    },
    _provider: {},
    set provider(rest) {
        if (rest) LocalData.set(Config.LOCAL_DATA_KEYS.PROVIDER, JSON.stringify(rest));
        else LocalData.remove(Config.LOCAL_DATA_KEYS.PROVIDER);

        this._provider = rest;
    },
    get provider() {
        return this._provider && Object.keys(this._provider).length > 0 ? this._provider : JSON.parse(LocalData.get(Config.LOCAL_DATA_KEYS.PROVIDER, '{}'));
    },
    remote_getAllById: (id, locale = Config.DEFAULT_LANG_CODE) => {
        return Services.getApi(Config.getBasePath() + `/provider-filters?providerId=${id}&locale=${locale}`);
    },
    createProviderFilter: (filter) => {
        return Services.postApi(Config.getBasePath() + '/provider-filters', { providerId: filter.restId, filters: [{ name: filter.name, acronym: filter.acronym }] });
    },
    updateProviderFilter: (filter) => {
        return Services.putApi(Config.getBasePath() + `/provider-filters/${filter.id}`, { name: filter.name, acronym: filter.acronym });
    },
    remote_getAll: () => {
        return Services.getApi(Config.getBasePath() + '/filters');
    },

    createFilter: (filter) => {
        return Services.postApi(Config.getBasePath() + '/filters', filter);
    },
    updateFilter: (filter) => {
        return Services.putApi(Config.getBasePath() + `/filters/${filter.id}`, { name: filter.name, acronym: filter.acronym });
    },
    deleteFilterById: (id) => {
        return Services.deleteApi(Config.getBasePath() + `/filters/${id}`);
    },
};

export default FilterModel;
