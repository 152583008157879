import React from 'react';
import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import FilterModel from '../../data/filter-model';
import { default as minus } from './../trash.svg';
import RawItemModal from './raw-item-modal';
import SelectedFilterOutIcon from './selected-filter-out-icon';
import { selectProviderId } from '../../redux/provider/selectors';
import { useSelector } from 'react-redux';

const customStyles = {
    content: {
        top: '0',
        left: 'auto',
        right: '0',
        bottom: 'auto',
        width: '500px',
        backgroundColor: 'black',
    },
};

const RawItem = ({ item, index, modifier, setModifier, setFilters, filters }) => {
    const providerId = useSelector(selectProviderId);

    useEffect(() => {
        setModifier({
            ...modifier,
            rawItems: modifier.rawItems.map(it => ({ ...it, price: it.price ? it.price : 0, filtersOut: it.filtersOut ? it.filtersOut : [] })),
        });
    }, []);

    const deleteOption = () => {
        setModifier({
            ...modifier,
            rawItems: modifier.rawItems.filter(it => it.name !== item.name),
        });
    };

    const [show, setShow] = useState(false);

    const showModal = () => {
        setShow(true);
    };
    const hideModal = () => {
        setShow(false);
    };

    const changeName = ({ target: { value } }) =>
        setModifier({
            ...modifier,
            rawItems: modifier.rawItems.map((it, idx) => idx === index ? { ...it, name: value } : { ...it }),
        });
    const changePrice = ({ target: { value } }) => {
        setModifier({
            ...modifier,
            rawItems: modifier.rawItems.map((it, idx) => idx === index ? { ...it, price: value > 1000 ? 1000 : value } : { ...it }),
        });
    };

    return (
        <div className="flex-start-center mod-options-container">
            <ReactModal
                isOpen={show}
                ariaHideApp={false}
                style={customStyles}>
                <RawItemModal
                    index={index}
                    setFilters={setFilters}
                    filters={filters}
                    modifier={modifier}
                    setModifier={setModifier}
                    rawItem={item}
                    hideModal={hideModal}
                />
            </ReactModal>
            <div className="option-item flex-between">
                <div style={{ display: 'flex' }}>
                    <span style={{ marginTop: 25 }}>
                        <svg width="32" height="8" viewBox="0 0 32 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1_334)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M30.0579 8H2.13217C1.79456 8 1.47078 7.85953 1.23205 7.60948C0.993319 7.35943 0.859205 7.02029 0.859205 6.66667C0.859205 6.31305 0.993319 5.97391 1.23205 5.72386C1.47078 5.47381 1.79456 5.33333 2.13217 5.33333H30.0579C30.3955 5.33333 30.7193 5.47381 30.958 5.72386C31.1968 5.97391 31.3309 6.31305 31.3309 6.66667C31.3309 7.02029 31.1968 7.35943 30.958 7.60948C30.7193 7.85953 30.3955 8 30.0579 8ZM30.0579 2.66667H2.13217C1.79456 2.66667 1.47078 2.52619 1.23205 2.27614C0.993319 2.02609 0.859205 1.68696 0.859205 1.33333C0.859205 0.979711 0.993319 0.640573 1.23205 0.390525C1.47078 0.140476 1.79456 0 2.13217 0H30.0579C30.3955 0 30.7193 0.140476 30.958 0.390525C31.1968 0.640573 31.3309 0.979711 31.3309 1.33333C31.3309 1.68696 31.1968 2.02609 30.958 2.27614C30.7193 2.52619 30.3955 2.66667 30.0579 2.66667Z" fill="#999999" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1_334">
                                    <rect width="30.4733" height="8" fill="white" transform="matrix(-1 0 0 1 31.333 0)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>

                    <div className="info-item info-title" data-info="filter_name">
                        <input
                            className="raw-item-input"
                            placeholder="item name"
                            value={item.name ?? ''}
                            onChange={changeName}
                            id="filter_name"
                            name="filter_name"
                            type="text"
                        />
                    </div>
                </div>
                <div className="flex-between">
                    <div className="has-width flex-between">
                        <div className="grid-flex">
                            <span className="modifier-filters-wrapper" style={{ display: 'flex', justifyContent: 'flex-end', width: 170 }}>
                                {item.filtersOut &&
                                    item.filtersOut.map((item, index) =>
                                        item.selected &&
                                        <SelectedFilterOutIcon
                                            key={index}
                                            name={item.name}
                                        />,
                                    )}
                            </span>
                            <button
                                onClick={showModal}
                                className="btn default-btn circled-btn plus-btn"
                                data-attribute="modal-action"
                                data-type="modal-add-option">
                            </button>
                        </div>

                        {providerId ?
                            <>
                                <div className="flex-end mods-price-create-container">
                                    {/* <label htmlFor="mod-3-price">+</label> */}
                                    <input
                                        type="number"
                                        value={item.price === 0 ? '' : item.price ?? ''}
                                        onChange={changePrice}
                                        id="mod-3-price"
                                        min={0}
                                        max={1000}
                                        className="mods-price-create"
                                    />
                                </div>
                            </>
                            :
                            <></>
                        }
                        <div className="flex-endn mods-btns-container">
                            <button className="copy">
                                {/*<svg width="31" height="29" viewBox="0 0 31 29" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                                {/*    <path d="M0 11.6753V25.7956C0.00494608 26.6256 0.324004 27.42 0.887844 28.0062C1.45168 28.5924 2.21475 28.9231 3.01122 28.9263H17.7158C18.5114 28.9226 19.2734 28.5915 19.836 28.0052C20.3985 27.4188 20.716 26.6247 20.7195 25.7956V11.6753C20.7166 10.8453 20.3994 10.0501 19.837 9.46239C19.2745 8.87471 18.5123 8.54207 17.7158 8.53674H3.01122C2.21401 8.54188 1.45086 8.87421 0.887194 9.46171C0.323528 10.0492 0.00476547 10.8445 0 11.6753ZM12.6977 0C11.8129 0.00481955 10.9657 0.373827 10.3408 1.02663C9.71586 1.67943 9.36381 2.56313 9.36134 3.48521V6.44548H17.7158C20.4726 6.44548 22.7245 8.81161 22.7245 11.6753V20.3896H26.7344C27.6198 20.3855 28.4678 20.0168 29.0934 19.364C29.7191 18.7111 30.0718 17.827 30.0746 16.9044V3.48521C30.0718 2.5629 29.7194 1.67911 29.0941 1.02632C28.4688 0.373535 27.6214 0.00464571 26.7363 0H12.6977Z" fill="white"/>*/}
                                {/*</svg>*/}
                            </button>
                            <button onClick={deleteOption} className="remove">
                                <img src={minus} alt="delete item" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RawItem;
