import React from 'react';
import { useEffect, useState } from 'react';
import ItemModel from '../../data/item-model';
import RootCategoryModel from '../../data/rootCategory-model';
import { default as magnifier } from './../magnifying-glass.svg';
import RootCategoryItemModal from './rootCategory-item-modal';
import { useDispatch, useSelector } from 'react-redux';
import HCNLoader from '../../helpers/hcn-loader';
import { toast } from 'react-toastify';
import { store } from '../../redux/store';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { selectProviderId } from '../../redux/provider/selectors';

const RootCategoryItemsModal = ({ closeModal, featureSectionItems, featureSection, type = 'section', setFeatureSectionItems, active }) => {
    const providerId = useSelector(selectProviderId);
    const [items, setItems] = useState([]);
    const [sectionItems, setSectionItems] = useState([]);
    const [searcheditems, setSearchedItems] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        HCNLoader.show(dispatch);
        ItemModel
            .getRootCategoryItems(providerId)
            .then(res => {
                if (res.length === 0) {
                    closeModal();
                }

                setItems(res.filter(x => featureSectionItems.find(a => x.id === a.id)?.id !== x.id).map(it => {
                    let featuredSectionItem = featureSectionItems.find(x => x.id === it.id);
                    it.price2 = featuredSectionItem && featuredSectionItem.price2 ? featuredSectionItem.price2 : null;

                    return { ...it, suggested: false };
                }));
                setSearchedItems(res.filter(x => featureSectionItems.find(a => x.id === a.id)?.id !== x.id).map(it => {
                    let featuredSectionItem = featureSectionItems.find(x => x.id === it.id);
                    it.price2 = featuredSectionItem && featuredSectionItem.price2 ? featuredSectionItem.price2 : null;

                    return { ...it, suggested: false };
                }));
                HCNLoader.hide(dispatch);
            })
            .catch(err => {
                toast(err.message);
                HCNLoader.hide(dispatch);
            });
    }, [providerId, closeModal, featureSectionItems, dispatch]);

    const addItems = async () => {
        if (type === 'section') {
            HCNLoader.show(dispatch);
            RootCategoryModel
                .putItemsToRootCategorySection(featureSection.id, sectionItems.map(it => it.id))
                .then(res => {
                    closeModal();
                    HCNLoader.hide(dispatch);
                })
                .catch(err => {
                    toast(err.message);
                    HCNLoader.hide(dispatch);
                });
        } else if (type === 'category') {
            HCNLoader.show(dispatch);
            try {
                for (const item of featureSectionItems) {
                    await ItemModel.deleteRootCategoryCategoryItem(featureSection.id, item.id);
                }
                for (const item of sectionItems) {
                    await RootCategoryModel.putItemToRootCategoryCategory(featureSection.id, {
                        itemId: item.id,
                        available: true,
                        price: parseFloat(item.price2 ? item.price2 : item.price),
                        suggested: item.suggested,
                        meta: {},
                    });
                }
                HCNLoader.hide(dispatch);
                closeModal();
            } catch (err) {
                toast(err.message);
                HCNLoader.hide(dispatch);
            }
        }
    };

    const clearAll = () => {
        setFeatureSectionItems([])
        closeModal();
    }

    const searchItems = ({ target: { value } }) => {
        value
            ? setSearchedItems(
                items.filter(x =>
                    x.name.toLowerCase().includes(value.toLowerCase()) ||
                    x.description.toLowerCase().includes(value.toLowerCase()),
                ),
            )
            : setSearchedItems(items)
    };

    return (
        <div id="select-item-modal" className="select-item-modal modal-container" data-attribute="modal-window">
            <div className="modal-wrapper modal-lg">
                <button type="button" onClick={closeModal} className="close-modal" data-dismiss="modal">x</button>
                {items.length === 0 ? <span style={{ marginTop: 30 }}>{getTranslatedText('selectedAllItemsWarning', store)}</span> : <form action="">
                    <div className="modal-head">
                        <div className="tabbed-options flex-between border-bottom-gray">
                            <h3 className="modal-title reset">
                                {getTranslatedText('selectItems', store)}
                            </h3>
                            <div className="side-container">
                                <button type="button" onClick={addItems} className="default-btn">{type === 'section' ? getTranslatedText('addFeaturedSection', store) : getTranslatedText('addToCategory', store)}</button>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="search-bar-container">
                            <label htmlFor="searchBar" className="screen-reader-text">{getTranslatedText('searchbar', store)}</label>
                            <input type="search" id="searchBar" placeholder="search an item" onChange={searchItems} />
                            <button type='button' className="search-btn flex-center">
                                <img src={magnifier} alt="magnifier" />
                            </button>
                        </div>
                        <div className="modal-tabled-display">
                            <div className="header flex-between border-bottom margin-top">
                                <div className="empty-space-50"></div>
                                <div className="flex-between-1">
                                    <h3>
                                        {getTranslatedText('rootCategoryItems', store)}
                                    </h3>
                                    <div className="flex-end">
                                        <p className="width-100">{getTranslatedText('price', store)}</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {searcheditems && searcheditems.map((modalItem, index) =>
                                    <RootCategoryItemModal
                                        setFeatureSectionItems={setFeatureSectionItems}
                                        featureSectionItems={featureSectionItems}
                                        key={index + 'inMd'}
                                        sectionItems={sectionItems}
                                        setSectionItems={setSectionItems}
                                        modalItem={modalItem}
                                        type={type}
                                        active={active}
                                    />,
                                )}
                            </div>
                        </div>
                    </div>
                </form>}
            </div>
        </div>
    );
};
export default RootCategoryItemsModal;
