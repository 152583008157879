import Config from '../Config';
import Services from './services';

const RootCategoryModel = {
    createRootCategory: (item) => {
        return Services.postApi(Config.getBasePath() + '/root-categories', item);
    },
    updateRootCategory: (id, item) => {
        return Services.putApi(Config.getBasePath() + `/root-categories/${id}`, item);
    },
    reorder: (id, item) => {
        return Services.putApi(Config.getBasePath() + `/root-categories/${id}/ranked`, item);
    },
    getAllRootCategorys: (id, locale = Config.DEFAULT_LANG_CODE) => {
        return Services.getApi(Config.getBasePath() + `/root-categories?providerId=${id}&locale=${locale}`);
    },
    deleteRootCategory: (id) => {
        return Services.deleteApi(Config.getBasePath() + `/root-categories/${id}`);
    },
    createRootCategoryCategory: (item) => {
        return Services.postApi(Config.getBasePath() + '/categories', item);
    },
    updateCategory: (id, item) => {
        return Services.putApi(Config.getBasePath() + '/categories/' + id, item);
    },
    updateRootCategoryCategory: (item) => {
        return Services.putApi(Config.getBasePath() + `/categories/${item.id}`, item);
    },
    getRootCategoryCategories: (rootCategoryId, locale = Config.DEFAULT_LANG_CODE) => {
        return Services.getApi(Config.getBasePath() + `/categories?rootCategoryId=${rootCategoryId}&locale=${locale}`);
    },
    putOrderItemsInRootCategoryCategories: (id, item) => {
        return Services.putApi(Config.getBasePath() + `/categories/${id}/ranked-items`, item);
    },
    getRootCategoryById: (rootCategoryId, locale = Config.DEFAULT_LANG_CODE) => {
        return Services.getApi(Config.getBasePath() + `/root-categories/${rootCategoryId}?locale=${locale}`);
    },
    deleteRootCategoryCategory: (id) => {
        return Services.deleteApi(Config.getBasePath() + `/categories/${id}`);
    },
    getRootCategorySections: (rootCategoryId) => {
        return Services.getApi(Config.getBasePath() + `/sections?rootCategoryId=${rootCategoryId}`);
    },
    createRootCategorySection: (section) => {
        return Services.postApi(Config.getBasePath() + '/sections', section);
    },
    updateRootCategorySection: (id, section) => {
        return Services.putApi(Config.getBasePath() + `/sections/${id}`, section);
    },
    deleteRootCategorySection: (id) => {
        return Services.deleteApi(Config.getBasePath() + `/sections/${id}`);
    },
    putItemsToRootCategorySection: (id, items) => {
        return Services.putApi(Config.getBasePath() + `/sections/${id}/items`, items);
    },
    putItemToRootCategoryCategory: (id, item) => {
        return Services.putApi(Config.getBasePath() + `/categories/${id}/items`, item);
    },
    getRootCategorySectionItems: (id) => {
        return Services.getApi(Config.getBasePath() + `/sections/${id}/items`);
    },
    deleteSectionItem: (sectionId, rootCategoryId) => {
        return Services.deleteApi(Config.getBasePath() + `/sections/${sectionId}/items/${rootCategoryId}`);
    },
    putOrderCategoriesInRootCategory: (id, item) => {
        return Services.putApi(Config.getBasePath() + `/root-categories/${id}/ranked-categories`, item);
    },
};
export default RootCategoryModel;
