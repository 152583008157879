import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import FilterModel from "../../data/filter-model";
import ModifierModel from "../../data/modifier-model";
import MainMenuBtn from "../menu-settings/main-menu-btn";
import SideBar from "../side-bar/side-bar";
import AddModifierProviderFields from "./add-modifier-provider-fields";
import RawItem from "./raw-item";
import { toast } from "react-toastify";
import { store } from "../../redux/store";
import { getTranslatedText } from "../../helpers/checkTranslations";
import { useDispatch, useSelector } from "react-redux";
import {
    selectProviderId,
    selectProviderLocales,
} from "../../redux/provider/selectors";
import { useStateSafe } from "../../hooks";
import { LangTabs } from "../translations/language-tabs";
import HCNLoader from "../../helpers/hcn-loader";
import Config from "../../Config";

const AddModifier = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const providerId = useSelector(selectProviderId);
    const langs = useSelector(selectProviderLocales);

    const { modifierName, modifierId } = useParams();
    const match = useRouteMatch(
        "/add-modifier/:modifierId?/:modifierName?/double"
    );

    const [modifier, setModifier] = useStateSafe({
        name: "",
        required: false,
        type: 0,
        title: "",
        min: 0,
        max: 0,
        rawItems: [],
    });
    const [filters, setFilters] = useStateSafe([]);
    const [draggedList, updateDraggedList] = useState(null);
    const [langCode, setLangCode] = useState(Config.DEFAULT_LANG_CODE);

    const isProviderModifier = !!providerId;
    const isClone = !!match;
    const isEdit = !isClone && !!modifierId;
    const headerTitle = isClone
        ? "Clone Modifier"
        : isEdit
        ? "Edit Modifier"
        : getTranslatedText("addModifier", store);
    const isDefaultLangSelected = langCode === Config.DEFAULT_LANG_CODE;
    const hideUntranslatableSection = isEdit && !isDefaultLangSelected;

    const setModifierFouUpdateOrDouble = useCallback(
        (obj) => {
            setModifier({
                name: obj.name ?? "",
                required: obj.required ?? false,
                type: obj.type ?? 0,
                title: obj.title ?? "",
                min: obj.min ?? 0,
                max: obj.max ?? 0,
                rawItems: obj.rawItems.map((it) => {
                    return {
                        ...it,
                        name: it.name,
                        price: it.price,
                        filtersOut: it.filtersOut.map((fo) => {
                            return {
                                ...fo,
                                selected: true,
                            };
                        }),
                    };
                }),
            });
        },
        [setModifier]
    );

    const getHCNFilters = useCallback(() => {
        HCNLoader.show(dispatch);
        FilterModel.remote_getAll()
            .then((filters) => {
                let flts = filters.map((filter) => {
                    return {
                        ...filter,
                        selected: false,
                    };
                });
                setFilters(flts);
            })
            .finally(() => {
                HCNLoader.hide(dispatch);
            });
    }, [setFilters, dispatch]);

    const getProviderFilters = useCallback(() => {
        HCNLoader.show(dispatch);
        FilterModel.remote_getAllById(providerId, langCode)
            .then((filters) => {
                // if (filters.length > 0){
                let flts = filters.map((filter) => {
                    return {
                        ...filter,
                        selected: false,
                    };
                });
                setFilters(flts);
                // } else {
                //     history.push('/add-filter');
                // }
            })
            .finally(() => {
                HCNLoader.hide(dispatch);
            });
    }, [providerId, langCode, setFilters, dispatch]);

    useEffect(() => {
        updateDraggedList(modifier.rawItems.slice());
    }, [modifier]);

    const createOrUpdateProviderModifier = (modif) => {
        if (!modifier.name) {
            toast.warn("Please provide a modifier name", { autoclose: 1200 });
            return;
        }

        if (isEdit) {
            HCNLoader.show(dispatch);
            ModifierModel.updateProviderModifiers(modif, modifierId)
                .then(() => history.push("/modifiers"))
                .catch((err) => toast.error(err.message, { autoclose: 1200 }))
                .finally(() => {
                    HCNLoader.hide(dispatch);
                });
            return;
        }

        if (isClone) {
            if (modifierName === modif.name) {
                toast.warn(getTranslatedText("cloneModifierWarning", store), {
                    autoclose: 1200,
                });
                return;
            }
            HCNLoader.show(dispatch);
            ModifierModel.createProviderModifiers({
                modifiers: [modif],
                providerId,
            })
                .then(() => history.push("/modifiers"))
                .catch((err) => toast(err.message, { autoclose: 1200 }))
                .finally(() => {
                    HCNLoader.hide(dispatch);
                });

            return;
        }

        HCNLoader.show(dispatch);
        ModifierModel.createProviderModifiers({
            modifiers: [modif],
            providerId,
        })
            .then(() => history.push("/modifiers"))
            .catch((err) => toast(err.message, { autoclose: 1200 }))
            .finally(() => {
                HCNLoader.hide(dispatch);
            });
    };

    const createOrUpdateModifier = (modif) => {
        if (!modifierName) {
            toast.warn("Please provide a modifier name", { autoclose: 1200 });
            return;
        }

        if (isEdit) {
            HCNLoader.show(dispatch);
            ModifierModel.updateModifier(modifierId, modif)
                .then(() => history.push("/modifiers"))
                .catch((err) => toast(err.message, { autoclose: 1200 }))
                .finally(() => {
                    HCNLoader.hide(dispatch);
                });
            return;
        }

        if (isClone) {
            if (modifierName === modif.name) {
                toast.warn(getTranslatedText("cloneModifierWarning", store), {
                    autoClose: 1200,
                });
                return;
            }

            HCNLoader.show(dispatch);
            ModifierModel.createModifier(modif)
                .then(() => history.push("/modifiers"))
                .catch((err) => toast.error(err.message, { autoclose: 1200 }))
                .finally(() => {
                    HCNLoader.hide(dispatch);
                });
            return;
        }

        HCNLoader.show(dispatch);
        ModifierModel.createModifier(modif)
            .then(() => history.push("/modifiers"))
            .catch((err) => toast.error(err.message, { autoclose: 1200 }))
            .finally(() => {
                HCNLoader.hide(dispatch);
            });
    };

    const addModifierForm = () => {
        let modif = {
            ...modifier,
            rawItems: modifier.rawItems.map((item) => {
                return {
                    ...item,
                    name: item.name ? item.name : "",
                    filtersOut: item.filtersOut
                        ? item.filtersOut.map((item) => item.id)
                        : [],
                };
            }),
        };

        isProviderModifier
            ? createOrUpdateProviderModifier(modif)
            : createOrUpdateModifier(modif);
    };

    const cancelAction = () => {
        history.push("/modifiers");
    };

    const addRawItem = () => {
        setModifier({
            ...modifier,
            rawItems: modifier.rawItems.concat({}).map((item, index) => {
                return { ...item, id: index };
            }),
        });
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const items = Array.from(draggedList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        updateDraggedList(items);
        setModifier({ ...modifier, rawItems: items });
    };

    const handleTabChange = (newCode) => {
        setLangCode(newCode);
    };

    useEffect(() => {
        isProviderModifier ? getProviderFilters() : getHCNFilters();

        if (modifierId) {
            const promise = isProviderModifier
                ? ModifierModel.getProviderModifierById(modifierId, langCode)
                : ModifierModel.getModifierById(modifierId);

            HCNLoader.show(dispatch);

            promise
                .then((res) => {
                    setModifierFouUpdateOrDouble(res);
                })
                .finally(() => {
                    HCNLoader.hide(dispatch);
                });
        }
    }, [
        langCode,
        isProviderModifier,
        modifierId,
        getHCNFilters,
        getProviderFilters,
        setModifierFouUpdateOrDouble,
        dispatch,
    ]);

    return (
        <div>
            <div className="modal-layer"></div>

            <div className="divider grid-flex">
                <SideBar />
                <div id="page" className="site">
                    <div className="main-content">
                        <header className="site-header">
                            <div className="flex-between">
                                <h1 className="main-title">{headerTitle}</h1>
                                <div className="side-functionality">
                                    <div className="side-button grid-flex">
                                        <button
                                            onClick={cancelAction}
                                            type="button"
                                            className="btn"
                                        >
                                            {getTranslatedText("cancel", store)}
                                        </button>
                                        <button
                                            onClick={() => {
                                                if (
                                                    modifier.type === 1 &&
                                                    modifier.min >= modifier.max
                                                ) {
                                                    toast(
                                                        getTranslatedText(
                                                            "minMaxError",
                                                            store
                                                        )
                                                    );
                                                } else if (
                                                    modifier.type === 1 &&
                                                    (modifier.min >
                                                        modifier.rawItems
                                                            .length ||
                                                        modifier.max >
                                                            modifier.rawItems
                                                                .length ||
                                                        modifier.min < 0 ||
                                                        modifier.max < 0)
                                                ) {
                                                    toast(
                                                        getTranslatedText(
                                                            "minMaxValidWarning",
                                                            store
                                                        )
                                                    );
                                                } else {
                                                    addModifierForm();
                                                }
                                            }}
                                            className="btn default-btn"
                                        >
                                            {getTranslatedText("save", store)}
                                        </button>
                                        <MainMenuBtn />
                                    </div>
                                </div>
                            </div>
                        </header>
                        <main className="spacing">
                            {isEdit ? (
                                <LangTabs
                                    langs={langs}
                                    selectedCode={langCode}
                                    handleTabChange={handleTabChange}
                                />
                            ) : null}
                            <div
                                className="info info-title"
                                data-info="filter_name"
                                style={{ marginTop: 30 }}
                            >
                                <label htmlFor="filter_name">
                                    {getTranslatedText("modifierName", store)}
                                    <sup>(*)</sup>
                                </label>
                                <input
                                    value={modifier.name}
                                    onChange={(event) =>
                                        setModifier({
                                            ...modifier,
                                            name: event.target.value,
                                        })
                                    }
                                    id="filter_name"
                                    name="filter_name"
                                    type="text"
                                />
                            </div>
                            <div
                                className="info info-title"
                                data-info="filter_name"
                            >
                                <label htmlFor="filter_name">
                                    {getTranslatedText(
                                        "displayForGuests",
                                        store
                                    )}
                                    <sup>(*)</sup>
                                </label>
                                <input
                                    value={modifier.title}
                                    id="filter_name"
                                    onChange={(event) =>
                                        setModifier({
                                            ...modifier,
                                            title: event.target.value,
                                        })
                                    }
                                    name="filter_name"
                                    type="text"
                                />
                            </div>
                            {providerId && !hideUntranslatableSection ? (
                                <AddModifierProviderFields
                                    modifier={modifier}
                                    setModifier={setModifier}
                                />
                            ) : (
                                null
                            )}

                            {!hideUntranslatableSection ? <section className="main-section">
                                <div className="section-head flex-end">
                                    <button
                                        onClick={addRawItem}
                                        type="button"
                                        className="default-btn"
                                    >
                                        {getTranslatedText(
                                            "addAnOption",
                                            store
                                        )}
                                    </button>
                                </div>
                                <div className="section-body options-wrapper">
                                    <div className="header flex-between border-bottom">
                                        <div className="empty-space-50"></div>
                                        <div className="flex-between-1">
                                            <h3>
                                                {getTranslatedText(
                                                    "modifierOptions",
                                                    store
                                                )}
                                            </h3>
                                            <div className="flex-end">
                                                <p className="mods-filter-opt">
                                                    {getTranslatedText(
                                                        "filter",
                                                        store
                                                    )}
                                                </p>
                                                {providerId ? (
                                                    <>
                                                        <p className="width-150">
                                                            {getTranslatedText(
                                                                "price",
                                                                store
                                                            )}
                                                        </p>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                                <div className="empty-space-150"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <DragDropContext
                                        onDragEnd={handleOnDragEnd}
                                    >
                                        <Droppable droppableId="modifiers">
                                            {(provided) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {modifier.rawItems &&
                                                        modifier.rawItems
                                                            .length > 0 &&
                                                        draggedList?.map(
                                                            (item, index) => (
                                                                <Draggable
                                                                    key={index}
                                                                    index={
                                                                        index
                                                                    }
                                                                    draggableId={`${item.name}${item.id}`}
                                                                >
                                                                    {(
                                                                        provided
                                                                    ) => (
                                                                        <div
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            ref={
                                                                                provided.innerRef
                                                                            }
                                                                        >
                                                                            <RawItem
                                                                                key={
                                                                                    index
                                                                                }
                                                                                index={
                                                                                    index
                                                                                }
                                                                                setFilters={
                                                                                    setFilters
                                                                                }
                                                                                filters={
                                                                                    filters
                                                                                }
                                                                                modifier={
                                                                                    modifier
                                                                                }
                                                                                setModifier={
                                                                                    setModifier
                                                                                }
                                                                                item={
                                                                                    item
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            )
                                                        )}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </section> : null}
                        </main>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddModifier;
