import React, { useEffect, useRef, useState } from 'react';
import './provider-groups.css';
import SideBar from '../side-bar/side-bar';
import MainMenuBtn from '../menu-settings/main-menu-btn';
import { toast } from 'react-toastify';
import ProviderModel from '../../data/provider-model';
import { useDispatch } from 'react-redux';
import Config from '../../Config';
import InfoModal from '../info-modal';
import { Link, useHistory } from 'react-router-dom';
import HotelModel from '../../data/hotel-model';
import GenerateLinkModal from '../generate-link-modal';
import providerModel from '../../data/provider-model';
import { getTranslatedText } from '../../helpers/checkTranslations';
import { store } from '../../redux/store';

const ProviderGroupInfo = () => {
    const [restGroups, setRestGroups] = useState(null)
    const [finalProviders, setFinalproviders] = useState(null)
    const [groupID, setGroupID] = useState(null)
    const [hotels, setHotels] = useState(null);
    const [show, setShow] = useState(false);
    const [showOrderModal, setShowOrderModal] = useState(false);
    const [infoMessage, setInfoMessage] = useState({
        message: '',
        confirmation: false,
    });
    const [orderInfoMessage, setOrderInfoMessage] = useState({
        message: '',
        data: null,
    });

    const dispatch = useDispatch();
    const history = useHistory()

    const mainContent = useRef()

    useEffect(() => {
        mainContent.current.scrollIntoView()

        dispatch({
            type: Config.REDUCER_TYPES.SET_LOADING,
            payload: true,
        });
        ProviderModel.getProviderGroups()
            .then(res => {
                setRestGroups(res)
                dispatch({
                    type: Config.REDUCER_TYPES.SET_LOADING,
                    payload: false,
                });
            })
            .catch(err => {
                toast(err.message);
                dispatch({
                    type: Config.REDUCER_TYPES.SET_LOADING,
                    payload: false,
                });
            });
        HotelModel.remote_getAll()
            .then(res => {
                setHotels(res.data);
                dispatch({
                    type: Config.REDUCER_TYPES.SET_LOADING,
                    payload: false,
                });
            })
            .catch(err => {
                toast(err.message);
                dispatch({
                    type: Config.REDUCER_TYPES.SET_LOADING,
                    payload: false,
                });
            });

        return () => {
            setRestGroups([]);
            setHotels([])
        };
    }, [dispatch]);

    useEffect(() => {
        if (restGroups && hotels) {
            setFinalproviders(restGroups.map(it => ({ ...it, hotelName: hotels.find(item => item.id === it.hotelId)?.name })))
        }
    }, [restGroups, hotels])

    useEffect(() => {
        if (infoMessage.confirmation) {
            ProviderModel.deleteProviderGroups(groupID)
            setFinalproviders(finalProviders.filter(item => item.id !== groupID))
        }
        return () => {
            setInfoMessage({
                message: '',
                data: null,
            });
        };
    }, [groupID, infoMessage.confirmation]);

    const hideModal = () => {
        setShow(false);
    };

    const deleteGroup = (id) => {
        setShow(true);
        setInfoMessage({
            ...infoMessage,
            message: getTranslatedText('deleteGroupWarning', store),
        });
        setGroupID(id)
    }

    const placeOrder = (item) => {
        setOrderInfoMessage({
            message: getTranslatedText('fillAllRequestedDetails', store),
            data: item,
        });
        setShowOrderModal(true);
    }

    const updateGroup = (item) => {
        history.push('edit-provider-group', { item })
    }

    return (
        <div className="divider grid-flex">
            <InfoModal
                setInfoMessage={setInfoMessage}
                infoMessage={infoMessage}
                hideModal={hideModal}
                show={show}
            />
            <GenerateLinkModal
                onPress={(group) => {
                    providerModel
                        .generateGroupLink(group.id, group.hotelId, group.roomNumber, group.firstName, group.lastName)
                        .then(data => {
                            window.open(data.url, '_blank', 'noopener,noreferrer');
                        });
                }}
                infoMessage={orderInfoMessage}
                hideModal={() => {
                    setShowOrderModal(false);
                }}
                show={showOrderModal}
            />

            <SideBar />
            <div id="page" className="site">
                <div className="main-content" ref={mainContent}>
                    <header className='site-header'>
                        <div className="flex-between">
                            <h2>
                                {getTranslatedText('providerGroups', store)}
                            </h2>
                            <div className="side-functionality">
                                <div className="side-button grid-flex">
                                    {finalProviders && hotels && finalProviders.length !== hotels.length &&
                                        <Link to='/add-provider-group' className="btn default-btn padding-top-10">
                                            {getTranslatedText('addGroup', store)}
                                        </Link>
                                    }
                                    <MainMenuBtn />
                                </div>
                            </div>
                        </div>
                    </header>
                    <main>
                        <div className='flex-between border-bottom margin-top-150 padding-top'>
                            <span style={{ width: '25%' }}>{getTranslatedText('groupName', store)}</span>
                            <span style={{ width: '25%' }}>{getTranslatedText('groupId', store)}</span>
                            <span style={{ width: '25%' }}>{getTranslatedText('hotel', store)}</span>
                            <span style={{ width: '15%' }}>{getTranslatedText('NumberOfProviders', store)}</span>
                            <span style={{ width: '10%' }}></span>
                        </div>
                        {finalProviders && finalProviders.map(item =>
                            <div key={item.id} className='flex-between border-bottom margin-top-20 padding-top'>
                                <span style={{ width: '25%' }} className="pointer" onClick={() => updateGroup(item)}>{item.name}</span>
                                <span style={{ width: '25%' }} className="pointer" onClick={() => updateGroup(item)}>{item.id}</span>
                                <span style={{ width: '25%' }} className="pointer" onClick={() => updateGroup(item)}>{item.hotelName}</span>
                                <span style={{ width: '15%' }}>{[].concat.apply([], item.providers)?.length}</span>
                                <span style={{ width: '10%' }}>
                                    <button className="remove margin-left-auto">
                                        <img src="../img/svg/url.svg" alt="double item" onClick={() => placeOrder(item)} />
                                    </button>
                                    <button className="remove margin-left-auto">
                                        <img src="../img/svg/trash.svg" alt="double item" onClick={() => deleteGroup(item.id)} />
                                    </button>
                                </span>
                            </div>,
                        )}
                    </main>
                </div>
            </div>
        </div>
    )
}

export default ProviderGroupInfo
