import React, {
    Children,
    cloneElement,
    createElement,
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

// https://easings.net/#easeInOutSine
const easeInOutSine = (x) => {
    return -(Math.cos(Math.PI * x) - 1) / 2;
};

const animate = (
    property,
    element,
    to,
    { cb = () => {}, duration = 300 } = { cb: () => {}, duration: 300 }
) => {
    let start = null;
    const from = element[property];
    let cancelled = false;
    const cancel = () => {
        cancelled = true;
    };

    const step = (time) => {
        if (cancelled) return;
        if (!start) start = time;
        const t = Math.min(1, (time - start) / duration);
        element[property] = easeInOutSine(t) * (to - from) + from;

        if (t >= 1) {
            requestAnimationFrame(() => cb(null));
            return;
        }

        requestAnimationFrame(step);
    };

    if (from === to) {
        cb(null);
        return cancel;
    }
    requestAnimationFrame(step);
    return cancel;
};

const debounce = (fn, delay = 166) => {
    let timeoutId;

    const debounced = (...args) => {
        const later = () => {
            fn.apply(this, args);
        };

        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(later, delay);
    };

    debounced.clear = () => {
        window.clearTimeout(timeoutId);
    };

    return debounced;
};

const prevItem = (list, item) => {
    if (list === item) return list.firstChild;
    if (item && item.previousElementSibling) return item.previousElementSibling;
    return list.lastChild;
};

const nextItem = (list, item) => {
    if (list === item) return list.firstChild;
    if (item && item.nextElementSibling) return item.nextElementSibling;
    return list.firstChild;
};

const focusElement = (list, currFocus, traversalFn) => {
    let wrappedOnce = false;
    let nextFocus = traversalFn(list, currFocus);

    while (nextFocus) {
        if (nextFocus === list.firstChild) {
            if (wrappedOnce) return;
            wrappedOnce = true;
        }

        const nextFocusDisabled =
            nextFocus.disabled ||
            nextFocus.getAttribute("aria-disabled") === "true";

        if (!nextFocus.getAttribute("tabindex") || nextFocusDisabled) {
            nextFocus = traversalFn(list, nextFocus);
        } else {
            nextFocus.focus();
            return;
        }
    }
};

export const TabPanel = ({
    index,
    activeIndex,
    className = "",
    as = "div",
    children,
}) => {
    const isSelected = activeIndex === index;
    const id = `panel-${index}`;
    // const style = { display: isSelected ? "block" : "none" };

    // return createElement(
    //           as,
    //           {
    //               id,
    //               style,
    //               className,
    //               role: "tabpanel",
    //               "aria-selected": "true",
    //           },
    //           children
    //       );
    return isSelected
        ? createElement(
              as,
              {
                  id,
                  className,
                  role: "tabpanel",
                  "aria-selected": "true",
              },
              children
          )
        : null;
};

export const TabItem = forwardRef(
    ({ selected, className = "", as = "button", children, ...props }, ref) => {
        const Tag = as;
        const tabClassName = `${className} ${
            selected ? "tab-item--active" : ""
        }`.trim();

        return (
            <Tag ref={ref} className={tabClassName} {...props}>
                {children}
            </Tag>
        );
    }
);

TabItem.displayName = "TabItem";

const NavBtnLeft = ({ disabled = false, onClick }) => {
    return (
        <>
            <div className="scroll-btn-container__left">
                <button
                    type="button"
                    disabled={disabled}
                    className="scroll-btn"
                    onClick={onClick}
                    aria-label="Scroll Tabbed Content Left"
                >
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                    >
                        <path d="M15 19l-7-7 7-7" />
                    </svg>
                </button>
            </div>
        </>
    );
};

const NavBtnRight = ({ disabled = false, onClick }) => {
    return (
        <>
            <div className="scroll-btn-container__right">
                <button
                    type="button"
                    disabled={disabled}
                    className="scroll-btn"
                    onClick={onClick}
                    aria-label="Scroll Tabbed Content Right"
                >
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                    >
                        <path d="M9 5l7 7-7 7" />
                    </svg>
                </button>
            </div>
        </>
    );
};

export const Tabs = ({ activeIndex, onTabClick, children }) => {
    // not all implmented but it's a start https://www.w3.org/WAI/ARIA/apg/patterns/tabs/
    const [navBtnState, setNavBtnState] = useState({
        left: false,
        right: false,
    });
    const tabRef = useRef([]);
    const tabsContainerRef = useRef(null);

    const scroll = (scrollValue) => {
        animate("scrollLeft", tabsContainerRef.current, scrollValue);
        // tabsContainerRef.current.scrollLeft = scrollValue;
    };

    const updateNavBtnState = useCallback(() => {
        const scrollLeft = tabsContainerRef.current.scrollLeft;
        const scrollWidth = tabsContainerRef.current.scrollWidth;
        const clientWidth = tabsContainerRef.current.clientWidth;
        const left = Math.floor(scrollLeft) > 1;
        const right = Math.ceil(scrollLeft) < scrollWidth - clientWidth - 1;

        setNavBtnState({
            left,
            right,
        });
    }, []);

    const scrollSelectedIntoView = useCallback(
        (index = activeIndex, isClicked) => {
            if (!tabsContainerRef.current) return;

            const selectedTabNode = tabRef.current[index];
            if (!selectedTabNode) return;

            const { left: tabsContainerLeft, right: tabsContainerRight } =
                tabsContainerRef.current.getBoundingClientRect();
            const {
                width: selectedTabWidth,
                left: selectedTabLeft,
                right: selectedTabRight,
            } = selectedTabNode.getBoundingClientRect();
            const tabsCenter =
                tabsContainerRef.current.clientWidth / 2 - selectedTabWidth / 2;
            const tabsEnd =
                tabsContainerRef.current.clientWidth - selectedTabWidth;
            const extraScroll = tabsCenter || tabsEnd;

            if (selectedTabLeft < tabsContainerLeft) {
                const scrollValue =
                    tabsContainerRef.current.scrollLeft +
                    (selectedTabLeft - tabsContainerLeft) -
                    extraScroll;
                scroll(scrollValue);
            } else if (selectedTabRight > tabsContainerRight) {
                const scrollValue =
                    tabsContainerRef.current.scrollLeft +
                    (selectedTabRight - tabsContainerRight) +
                    extraScroll;
                scroll(scrollValue);
            }

            if (tabsCenter > selectedTabLeft && isClicked) {
                isClicked = false;
                const scrollValue =
                    tabsContainerRef.current.scrollLeft +
                    (selectedTabLeft - tabsContainerLeft) -
                    extraScroll;
                scroll(scrollValue);
            } else if (tabsCenter < selectedTabRight && isClicked) {
                isClicked = false;
                const scrollValue =
                    tabsContainerRef.current.scrollLeft +
                    (selectedTabRight - tabsContainerRight) +
                    extraScroll;
                scroll(scrollValue);
            }
        },
        [activeIndex]
    );

    const onNativeTabClick = useCallback(
        (event, index) => {
            onTabClick(event, index);
        },
        [onTabClick]
    );

    const handleTabScroll = useMemo(
        () =>
            debounce(() => {
                updateNavBtnState();
            }),
        [updateNavBtnState]
    );

    const handleKeyDown = (event) => {
        const list = tabsContainerRef.current;
        let currentFocusedEl = document.activeElement;
        const role = currentFocusedEl?.getAttribute("role");
        if (role !== "tab") return;
        let traversalFn;

        switch (event.code) {
            case "ArrowLeft": {
                traversalFn = prevItem;
                break;
            }
            case "ArrowRight": {
                traversalFn = nextItem;
                break;
            }
            case "Home": {
                currentFocusedEl = null;
                traversalFn = nextItem;
                break;
            }
            case "End": {
                currentFocusedEl = null;
                traversalFn = prevItem;
                break;
            }
            default:
                return;
        }

        event.preventDefault();
        focusElement(list, currentFocusedEl, traversalFn);
    };

    const onLeftNavBtnClick = (event) => {
        const scrollAmount = 4;
        const scrollLeft = tabsContainerRef.current.scrollLeft;
        const scrollValue =
            scrollLeft - tabRef.current[activeIndex].clientWidth * scrollAmount;

        scroll(scrollValue);
    };

    const onRightNavBtnClick = (event) => {
        const scrollAmount = 4;
        const scrollLeft = tabsContainerRef.current.scrollLeft;
        const scrollValue =
            scrollLeft +
            tabRef.current[activeIndex]?.clientWidth * scrollAmount;

        scroll(scrollValue);
    };

    useEffect(() => {
        return () => {
            handleTabScroll.clear();
        };
    }, [handleTabScroll]);

    useEffect(() => {
        const handleResize = debounce((event) => {
            updateNavBtnState();
            scrollSelectedIntoView();
        });

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [updateNavBtnState, scrollSelectedIntoView]);

    useEffect(() => {
        updateNavBtnState();
        scrollSelectedIntoView(activeIndex, true);
    }, [activeIndex, updateNavBtnState, scrollSelectedIntoView]);

    return (
        <div className="tabs-container">
            {navBtnState.left ? (
                <NavBtnLeft
                    onClick={onLeftNavBtnClick}
                    disabled={!navBtnState.left}
                />
            ) : null}
            <div
                ref={(nodeRef) => {
                    tabsContainerRef.current = nodeRef;
                }}
                role="tablist"
                aria-label="tabs"
                onKeyDown={handleKeyDown}
                onScroll={handleTabScroll}
                className="tab-list"
            >
                <>
                    {Children.map(children, (child, index) => {
                        const selected = index === activeIndex;

                        return cloneElement(child, {
                            ref: (nodeRef) => {
                                tabRef.current[index] = nodeRef;
                            },
                            role: "tab",
                            selected,
                            "aria-selected": selected,
                            tabIndex: selected ? 0 : -1,
                            className: `tab-item ${
                                child.props.className || ""
                            }`.trim(),
                            onClick: (e) => onNativeTabClick(e, index),
                        });
                    })}
                </>
            </div>
            {navBtnState.right ? (
                <NavBtnRight
                    onClick={onRightNavBtnClick}
                    disabled={!navBtnState.right}
                />
            ) : null}
        </div>
    );
};
