import {createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import Reducer from './reducer';
import {composeWithDevTools} from 'redux-devtools-extension';
import { providerReducer } from './provider/reducer';

const rootReducer = combineReducers({
    Reducer,
    provider: providerReducer,
});
export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
