import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getTranslatedText } from '../../../helpers/checkTranslations';
import { store } from '../../../redux/store';
import { default as plus } from '../plus-sign.svg';
const Filters = ({ providerId }) => {
    const history = useHistory();

    const goTo = () => {
        history.push("/add-filter");
    };

    return (
        <>
            <Link to="/filters">
                <p data-component="provider">{getTranslatedText('filters', store)}</p>
            </Link>
            <button onClick={goTo} className="btn add-btn">
                <span>
                    <img src={plus} alt="add" />
                </span>
            </button>

        </>
    );
};
export default Filters;
